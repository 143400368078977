.appointments-wrapper {
  background-color: #fff;
  /* height: auto; */
  padding: 2rem 2.4rem 4rem 2.4rem;
  border-radius: 10px;
}

.appointment-heading {
  font-size: 16px;
  font-weight: 600;
}

.appointment-title {
  font-family: inter, assistant;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000000d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.appointment-title span {
  font-weight: 500;
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.5);
}

.appointment-counts {
  font-size: 14px;
  font-weight: 400;
  color: rgb(167, 167, 167);
  margin-top: 2px;
  margin-left: 20px;
}

.appointments-content.is-dragging {
  margin-bottom: 1.6rem;
  border-radius: 1rem;
  background: #f8f9fb;
  padding-left: 1.3rem;
  margin-left: 0 !important;
  padding-top: 0.8rem;
  align-items: center;
}

.dragging-icon-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.name-and-location {
  display: flex;
}

.person-name {
  font-size: 16px;
  font-weight: 600;
}

.cc {
  color: #22a3c3;
}
.appointment-info-area {
  display: flex;
}
.appointment-info-area button {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #22a3c3;
  border-radius: 2px !important;
}

.appointment-info-title,
.appointment-info-value {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.5em;
}
.appointment-info-title {
  width: 10rem;
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #424242;
}

.appointment-info-value {
  color: rgba(0, 0, 0, 0.75);
  margin-left: 0.7rem;
  max-width: calc(100% - 10rem);
}
.appointment-info-value:hover,
.appointment-info-value:active {
  text-decoration: underline;
  cursor: pointer;
}
.dog-image {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  cursor: pointer;
}

.empty-pet {
  padding: 0.5rem;
  background: #000000;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.cat-image {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.animal-images {
  text-align: right;
  margin-right: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.empty-appointments {
  background-color: #fafafa;
  width: 100%;
  height: 15.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
}

.no-appointments-text {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000d9;
}

.more-btn-appointment {
  width: 3.2rem !important;
  height: 3.2rem !important;
}

.tooltip-wrapper {
  min-width: 9.6rem;
  padding: 0.8rem;
}

.tooltip-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.tooltip-item p {
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;
  margin: 0;
}

.appointments-header-wrapper {
  display: flex;
  align-items: center;
}

.appointments-header-wrapper button {
  width: auto;
  cursor: pointer;
  padding: 0 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  border-radius: 2px !important;
}

.appointments-header-wrapper button svg {
  margin-right: 9px;
}

.appointments-header-wrapper .reorder-btn {
  height: 32px;
  background: #ffffff;
  border: 1px solid rgba(45, 51, 99, 0.3);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  color: rgba(0, 0, 0, 0.85);
}

.appointment-btn {
  width: auto;
  height: 32px !important;
  background: #ff9a05;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  color: #ffffff;
  margin-left: 1.2rem;
}

.cancel-btn {
  color: #262626 !important;
}

.ant-popover-title {
  display: none !important;
}

.ant-popover-inner-content {
  background: #fff !important;
}

.appointment-popover-wrapper {
  width: 26.1rem;
  border-radius: 0.8rem;
  padding: 2rem 3.2rem 2rem 2rem;
}

.appointment-popover-item {
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.8rem 0.4rem;
}

.appointment-popover-item:hover {
  background: rgba(172, 188, 212, 0.17);
}

.appointment-popover-item svg {
  width: 2rem;
  height: 2rem;
  margin-right: 1.6rem;
}

.appointment-popover-item h3 {
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
  margin-bottom: 0;
}

.appointment-popover-item-icon {
  color: #424242;
}

.appointment-title__left {
  display: flex;
  align-items: center;
}
.appointment-title__left h3 {
  margin-bottom: 0;
}
.appointment-title__left span {
  margin-left: 0.5rem;
}

.location {
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .appointment-title {
    flex-wrap: wrap;
  }
  .appointments-header-wrapper {
    width: 100%;
  }
  .appointment-title__left {
    flex-wrap: wrap;
    margin-bottom: 2.4rem;
    position: relative;
  }
  .appointment-title__left h3 {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  .appointment-title__left span {
    margin-left: 0;
  }
  .appointments-header-wrapper {
    justify-content: flex-end;
  }
  .close-modal-icon {
    position: absolute;
    top: 2.35rem;
    right: 2.35rem;
  }
  .name-and-location {
    flex-wrap: wrap;
  }
  .eta-wrapper {
    display: none;
  }
  .person-name {
    margin-bottom: 0;
  }
  .location {
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .single-appointment-popover {
    display: none;
  }
  .appointment-row {
    align-items: flex-start;
  }
}
