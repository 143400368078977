.edit-client-modal-wrapper .ant-modal-body {
  padding: 3.2rem 4rem 0rem 4rem !important;
}

.edit-client-modal-wrapper .ant-modal {
  top: 2.5rem;
}

@media screen and (min-width: 600px) {
  .edit-client-modal-wrapper {
    min-width: 400px;
  }
}
@media screen and (min-width: 800px) {
  .edit-client-modal-wrapper {
    min-width: 600px;
  }
}
@media screen and (min-width: 1000px) {
  .edit-client-modal-wrapper {
    min-width: 800px;
  }
}
@media screen and (min-width: 1200px) {
  .edit-client-modal-wrapper {
    min-width: 1000px;
  }
}

/*
.pets-table > ul {
  justify-content: flex-start;
  width: 80%;
} */

.edit-client-modal-wrapper .ant-row-end .ant-form-item {
  margin-bottom: 0;
}

.edit-client-modal-wrapper .ant-modal-footer {
  /* position: absolute;
  bottom: 3.2rem;
  left: 0;
  border-top: unset !important; */
  padding: 0.8rem 1.6rem 1rem 1.6rem !important;
}

.pet-name-column {
  /* height: 2.2rem !important;
  padding: 1.3rem 2rem !important; */
  text-align: start !important;
}

.edit-client-modal-wrapper .ant-btn-primary {
  width: 6.7rem;
  height: 4rem;
  content: 'Save' !important;
}
.edit-client-modal-wrapper .ant-btn-default {
  width: 6.7rem;
  height: 4rem;
}

.column-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pet-images {
  /* min-width: 3.2rem;
  min-height: 3.2rem; */
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 5.7rem;
  margin-right: 1.2rem;
  object-fit: cover;
}

.pet-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plus-icon > svg {
  min-width: 2.4rem;
  min-height: 2.4rem;
  cursor: pointer;
}

.client-modal-title {
  width: 10.2rem;
  height: 2.4rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
}

.client-modal-subtitle {
  color: #434343;
  opacity: 0.75;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.subtitle-icon > svg {
  fill: #bfbfbf;
  margin-right: 0.6rem;
}

.edit-wrapper {
  width: 5rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clicked-delete-icon,
.edit-icon {
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.4rem 0.2rem 0.2rem;
  margin-right: 1.2rem;
}

.pet-table-delete-icon {
  padding: 0.2rem 0.4rem 0.2rem 0.2rem;
  margin-right: 1.2rem;
}

.pet-table-delete-icon > svg {
  width: 1.6rem;
  height: 1.6rem;
  color: #ff4d4f;
}

.edit-icon > svg {
  width: 1.6rem;
  height: 1.6rem;
  color: #000000d9;
}

.clicked-delete-icon > svg {
  width: 1.6rem;
  height: 1.6rem;
  color: #fa8c16;
}

.delete-popover {
  width: 25.6rem;
  height: 6rem;
  display: flex;
  gap: 0.9rem;
  margin-top: 0.6rem;
  align-items: center;
}

.click-popover {
  width: 21rem;
  height: 9.2rem;
}

.click-popover-main {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
}

.click-popover-title {
  color: white;
  width: 18.2rem;
  margin-left: 0.8rem;
}

.warning-icon-yellow {
  width: 2rem;
  height: 2rem;
}

.warning-icon-yellow > svg {
  color: #faad14;
}

.warning-icon {
  height: 2rem;
  margin-top: 1rem;
}

.warning-icon > svg {
  color: #ff4d4f;
}

.click-popover-button-wrapper {
  width: 100%;
  margin-top: 0.8rem;
  display: flex;
  gap: 0.8rem;
  justify-content: flex-end;
  align-items: center;
}

#delete-popconfirm .ant-btn-default {
  color: black;
  width: 3.4rem;
  height: 2.4rem;
  border: 0.1rem solid #d9d9d9;
  background-color: white;
  border-radius: 0.2rem;
  cursor: pointer;
}

#delete-popconfirm .ant-btn-primary {
  width: 3.9rem !important;
  height: 2.4rem !important;
  border: 0.1rem solid rgba(0, 0, 0, 0.043) !important;
  background: #ff9a05 !important;
  border-radius: 0.2rem !important;
  cursor: pointer;
}

#delete-popconfirm .ant-popover-inner-content {
  background-color: #fff !important;
}

#delete-popconfirm .ant-popover-message-title {
  width: 23rem !important;
  color: #000c17;
}

.no-pets-wrapper {
  height: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 0.8rem;
}

.no-pets-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-pets-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 0.9rem;
  margin-bottom: 1.6rem;
}

.add-new-pet-button {
  background: #ffffff;
  border: 0.1rem solid #22a3c3;
  border-radius: 0.2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #22a3c3;
  padding: 0.5rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-new-pet-button > svg {
  margin-left: 0;
  margin-right: 0.4rem;
  width: 2rem;
  height: 2rem;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.4rem;
}

.action-title {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000d9;
}

.ant-popover-placement-top {
  z-index: 10000 !important;
}
