.h1 {
}
.h2 {
}
.h3 {
}
.h4 {
}
.h5 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.pRegular {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.caption {
  color: var(--color-gray-B8);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.p1 {
}
.p2 {
}
.p3 {
}

.label, .error {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.error {
  color: var(--color-red);
}
