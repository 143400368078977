.container {
  display: flex;
  flex-direction: column;
}

.description {
  margin-top: 24px;
  opacity: 0.75;
}

.title,
.description {
  margin-top: 8px;
  color: var(rgba(0, 0, 0, 0.85));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.buttonNew {
  width: 100%;
  margin-top: 24px;
}

.buttonBack {
  width: 100%;
  margin-top: 10px;
}

.container :global(.ant-btn-primary) {
  width: 100%;
  background-color: #ff9a05 !important;
  border: 1px solid #ff9a05 !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
}
.container :global(.ant-btn-primary[disabled]),
.container :global(.ant-btn[disabled]) {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
