.container {
  width: 100%;
  min-width: 200px;
  height: 32px;
  display: flex;
  cursor: pointer;
}
.vetCheckbox {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
}
.box {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.select {
  width: 100%;
}

.select :global(.ant-select-selector), .select :global(.ant-select-arrow) {
  cursor: pointer !important;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select :global(.aaa) {

}

