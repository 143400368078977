@import '../../../../common_repo/styles/mixins.scss';

.eta-tooltip-wrapper {
  width: 100%;
  min-width: 14rem;
  max-width: 28.5rem;
  padding: 0.8rem 0;

  .eta-content-wrapper {
    display: flex;
    width: 100%;

    .eta-content {
      width: 50%;

      p {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 0.3rem;
      }

      .eta-content-text {
        font-weight: 600;
      }
    }

    .eta-content.text-center {
      text-align: center;
    }
  }
}

.single-appointment-item {
  display: flex;
  margin-bottom: 1.2rem;
  cursor: pointer;

  .appointment-row {
    display: flex;
    justify-content: space-between;
    padding: .8rem 1.3rem .8rem 2.4rem;
    width: 100%;

    &:hover {
      background: rgba(172, 188, 212, 0.17);
      border-radius: 1rem
    }

    .person-name {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      width: 14rem;
    }

    .location {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      opacity: 0.8;
      &:hover{
        text-decoration: underline;
      }
    }

    .appointment-row-right {
      display: flex;
      align-items: center;
    }

    .eta-wrapper {
      background: rgba(34, 163, 195, 0.1);
      height: 2.4rem;
      width: 2.6rem;
      padding: 0.3rem .7rem;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      overflow: hidden;

      &:hover {
        border-radius: 21px;
        width: 7.9rem;
        display: flex;
        align-items: center;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0;
      }

      p {
        font-family: inter, assistant;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #22A3C3;
        margin-bottom: 0;
        margin-left: .8rem;
      }
    }

    .eta-wrapper:hover.eta-wrapper p {
      animation-delay: .3s;
      animation: widthMove .6s;
    }

    @keyframes widthMove {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .single-appointment-popover {
      opacity: 0;
      margin-left: 1.4rem;
    }

    .animal-images {
      margin-right: 1.6rem;
    }
  }
}

.single-appointment-item:hover .single-appointment-popover {
  opacity: 1;
}

.appointments-content.is-dragging .appointment-row:hover {
  background: transparent;
}

.appointment-mobile-modal-wrapper {
  padding: 2rem;
  .appointment-mobile-modal-header{
    display: flex;
    align-items: center;
    margin-bottom: 1.85rem;
    justify-content: space-between;
  }
  .appointment-row{
    padding-left: 0;
  }
  .appointment-mobile-modal-content__eta-wrapper {
    background: #f6f6f6;
    border-radius: .2rem;
    padding: 0.9rem 1.5rem;
    p {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
  .appointment-popover-wrapper{
    width: 100%;
    margin-top: 2rem;
    border: 1px solid #D9D9D9;
    padding: 0!important;
    height: auto!important;
    border-bottom: 0;
    .appointment-popover-item{
      padding : 1.3rem 0 1rem 1.7rem;
      border-bottom: 1px solid #D9D9D9;
    }
  }
  .appointment-mobile-modal-footer{
    display: flex;
    margin-top: 4rem;
    justify-content: flex-end;
    .cancel-btn{
      width: 8.1rem;
      height: 4rem;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
    }
  }
}

.edit-appointment-details{
  width: 42.9rem;
  box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);
  border-radius: .4rem;
  padding : 2.4rem 3.5rem 2.4rem 4rem;
  @include sm{
    width: 100%;
  }

  h1{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem!important;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
  h2{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.2rem!important;
  }
  textarea{
    width: 100%;
    height: 7.1rem!important;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    resize : none;
    padding : 1.2rem;
    margin-bottom: 2.4rem;
  }
  .edit-appointment-footer{
    text-align: right;
    button{
      margin-left: 1.2rem;
    }
  }
}
