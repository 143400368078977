.timeslot-row {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.3em;
  text-transform: capitalize;
}
.timeslot-row:hover {
  text-overflow: ellipsis;
  overflow: visible;
  white-space: normal;
}
.additional-info-wrapper .property-type-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.additional-info-wrapper .additional-info {
  font-family: Inter, serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
}
.additional-info-wrapper :global(.ant-col) {
  max-width: unset;
}
.additional-info-wrapper textarea.ant-input {
  max-width: 100%;
  height: auto !important;
  min-height: 32px;
  resize: none;
  /*height: unset !important;*/
  line-height: 1.5715;
  vertical-align: bottom;
  transition:
    all 0.3s,
    height 0s;
}
