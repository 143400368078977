.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close {
}

.title {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
