.list {
  flex: 2;
  box-shadow: 0px 4px 45px -31px #2d1c1c14;
  background-color: #fff;
  padding: 16px 16px 16px 32px;
  border-radius: 0px 8px 8px 0px;
  height: 408px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    height: 90%;
    background-color: #f5f5f5;
    transform: translateY(-50%);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleText {
  font-size: 16px;
  font-weight: 500;
}

.container {
  height: 100%;
  max-height: 330px;
  margin-top: 14px;
  overflow-y: auto;
}

.container :global(.ant-table-placeholder td) {
  padding: 126px 0;
}

.filterByText {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-595959);
  margin-right: 4px;
}

.datePicker {
  width: 155px;
}
