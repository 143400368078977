.timeslotItem {
  border: 1px solid #d9d9d9;
  display: flex;
  position: relative;
  padding: 17px 10px;
  background: #fff;
}
.timeslotItem > * {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px;
  cursor: pointer;
}
.timeslotItem:hover,
.activeTimeslotItem {
  border-color: #22a3c3;
}
.disabledTimeslotItem {
  cursor: default;
}
.distanceContainer {
  align-items: center;
  flex: 1;
  border-right: 1px solid #f0f0f0;
  min-width: 70px;
}
.dateContainer {
  flex: 2;
  min-width: 100px;
}
.vetContainer {
  flex: 2;
  border-left: 1px solid #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tsName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}
.vetLabel {
  margin-bottom: 0px;
}

.marks {
  min-height: unset;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.markFull,
.markLocked {
  padding: 2px 8px 2px 8px;
  border-radius: 1px 0px 0px 0px;
}
.markFull {
  background: #ff4b5529;
  color: #ff4b55;
}
.markLocked {
  background: #ff9a0529;
  color: #ff9a05;
}

/* calendar */
.timeslot,
.timeslots {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeslot {
  justify-content: center;
  align-items: center;
  color: #22a3c3;
  background-color: #fff;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  border: 1px solid #eee;
}
.timeslot span {
  margin: 0;
  padding: 0;
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px;
}
.selected {
  background-color: #22a3c3;
  color: #fff;
  border: 1px solid #22a3c3;
}
.disabled {
  background-color: #fff;
  color: #b4b8cd;
  cursor: default;
}
.themeLight {
  border: 1px solid #eee;
}
.themeLight.selected {
  border: 1px solid #22a3c3;
}

.footer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 4px;
  right: 4px;
  min-height: 0px;
  padding: 0px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.info {
  color: var(--neutral-6) !important;
  font-size: 14px;
}

.info:hover {
  color: var(--color-black) !important;
}

.info:hover + .timeslotRouteSummary {
  display: block;
}

.iconWithText {
  display: flex;
  align-items: center;
}

.tooltip :global(.ant-tooltip-inner) {
  border-radius: 4px !important;
}
.tooltip :global(.ant-tooltip-arrow) {
  margin-top: 4px;
}
.container {
  display: flex;
  flex-direction: column;
}
