.container {
  width: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verticalLine {
  height: 12px;
  opacity: 40%;
}

.iconWithText {
  display: flex;
  align-items: center;
}

.routeSummaryContainer {
  width: 336px;
}
.modalBody {
  min-width: unset !important;
}

.hr {
  opacity: 40%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.overTime {
  font-weight: 600;
}

.icon {
  cursor: auto;
}
