@import './fonts.css';

:root {
  --color-orange: #ff9a05;
  --color-gray-bg: #f5f5f8;
  --color-blue-disabled: #acbcd4;
  --color-orange-light: #ffba53;
  --color-blue: #22a3c3;
  --color-blue-light: #eef8fb;
  --color-purple: #2d3363;
  --color-violet: #a68bff;
  --color-violet-2: #f8f6ff;
  --color-red: #ff4b55;
  --color-red-2: #fdf1f2;
  --color-blue-op-06: rgba(34, 163, 195, 0.06);
  --color-blue-dark: #24697b;
  --color-gray-D9: #d9d9d9;
  --color-gray-F5: #f5f5f5;
  --color-gray-B8: #b8b8b8;
  --color-gray-99: #999999;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-black-1-op-09: rgba(172, 188, 212, 0.09);
  --color-solitude-e8: #e8ecf4;
  --character-secondary-45: rgba(0, 0, 0, 0.45);
  --character-title-85: rgba(0, 0, 0, 0.85);
  --color-text-blue: #112950;
  --color-gray-border: #dbe2ee;
  --color-gray-hover: #eaebee66;
  --color-black-op-64: #000000A3;
}
