.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    background-color: var(--color-gray-FAFAFA);
}

.text {
    max-width: 188px;
    text-align: center;
}