.new_wrapper .new-appointment-wrapper {
  /*padding: 20px 0;*/
}
.new_wrapper .new-appointment-wrapper .appointments-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 40px;
  gap: 10px;
}
.new_wrapper .new-appointment-wrapper .appointments-stepper-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new_wrapper .new-appointment-wrapper .appointments-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.6rem;
}
.new_wrapper .new-appointment-wrapper .appointments-footer-wrapper button {
  max-width: 8.1rem;
  height: 4rem;
  border-radius: 0.2rem;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}
.new_wrapper .new-appointment-wrapper .appointments-footer-wrapper .cancel-btn {
  margin-right: 1.2rem;
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85);
}
.new_wrapper .new-appointment-wrapper .appointments-footer-wrapper .next-btn.disabled {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
  cursor: not-allowed !important;
}
.new_wrapper .new-appointment-wrapper .search-input {
  max-width: 17.3rem;
  height: 3.2rem;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state {
  text-align: center;
  margin-top: 2.4rem;
}
@media (max-width: 768px) {
  .new_wrapper .new-appointment-wrapper .clients-empty-state {
    margin-bottom: 2rem;
  }
}
.new_wrapper .new-appointment-wrapper .clients-empty-state h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.8rem;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #36517e;
  margin-bottom: 1.6rem;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state img {
  margin-bottom: 1.6rem;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state .copy-url-input {
  width: 100%;
  max-width: 38.6rem;
  margin-bottom: 1.6rem;
  display: block;
  margin: auto;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state .copy-url-input input {
  border-right: 0;
}
.new_wrapper
  .new-appointment-wrapper
  .clients-empty-state
  .copy-url-input
  .ant-input-group-addon:last-child {
  background: #f5f5f5;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state .copy-icons-wrapper {
  display: flex;
  align-items: center;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state .copy-icons-wrapper .copy-svg svg {
  fill: #585ce5;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state .copy-icons-wrapper .share-svg svg {
  fill: #22a3c3;
}
.new_wrapper .new-appointment-wrapper .clients-empty-state .line-between-icons {
  width: 0.1rem;
  height: 1.6rem;
  margin: 0 0.55rem 0 0.85rem;
  background: #22a3c3;
  opacity: 0.2;
  display: inline-flex;
}
.new_wrapper .new-appointment-wrapper .appointments-content-wrapper {
}
@media (max-width: 768px) {
  .new_wrapper .new-appointment-wrapper .appointments-content-wrapper {
  }
}

.new_wrapper .new-appointment-wrapper .appointments-content-wrapper .client-card-wrapper {
  width: 100%;
  height: 10rem;
  border: 1px solid #c9d4e3;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
}

.new_wrapper .new-appointment-wrapper .appointments-content-wrapper .client-card-wrapper.active {
  box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.2);
  border-color: #22a3c3;
}
.new_wrapper
  .new-appointment-wrapper
  .appointments-content-wrapper
  .client-card-wrapper
  .client-details-wrapper
  h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #112950;
  opacity: 0.8;
  margin-bottom: 0.4rem;
  margin-top: 0;
}
.new_wrapper
  .new-appointment-wrapper
  .appointments-content-wrapper
  .client-card-wrapper
  .client-details-wrapper
  h5 {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.45);
}
.new_wrapper
  .new-appointment-wrapper
  .appointments-content-wrapper
  .client-card-wrapper
  .client-details-wrapper
  h5:hover {
  text-decoration: underline;
}
.new_wrapper
  .new-appointment-wrapper
  .appointments-content-wrapper
  .client-card-wrapper
  .client-details-wrapper
  .address-wrapper {
  display: flex;
}
.new_wrapper
  .new-appointment-wrapper
  .appointments-content-wrapper
  .client-card-wrapper
  .client-details-wrapper
  .address-wrapper
  h4 {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #585ce5;
  margin-top: 0;
  margin-right: 1rem;
  margin-bottom: 0;
}
.new_wrapper
  .new-appointment-wrapper
  .appointments-content-wrapper
  .client-card-wrapper
  .client-details-wrapper
  svg {
  fill: #22a3c3;
}
.new_wrapper .vet-calendar-steps {
  border-radius: 0.4rem;
  background: #fff;
  box-shadow: 0 4px 45px -31px rgba(45, 28, 28, 0.08);
}
.new_wrapper .vet-calendar-steps .ant-form-item-with-help {
  margin-bottom: 2.4rem !important;
}
.new_wrapper .vet-calendar-steps .ant-steps-item {
  flex: initial !important;
}
.new_wrapper
  .vet-calendar-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: rgba(255, 154, 5, 1);
  border: none;
}
.new_wrapper .vet-calendar-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(56, 158, 13, 1);
}
.new_wrapper .vet-calendar-steps span.anticon.anticon-check.ant-steps-finish-icon {
  color: rgba(56, 158, 13, 1);
}
.new_wrapper .vet-calendar-steps .text-right {
  text-align: right;
}
.new_wrapper .vet-calendar-steps .ant-btn-primary {
  background-color: #ff9a05 !important;
  border: 1px solid #ff9a05 !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
}
.new_wrapper .vet-calendar-steps .ant-btn-primary[disabled],
.new_wrapper .vet-calendar-steps .ant-btn[disabled] {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.new_wrapper .vet-calendar-steps .ant-steps-item-title {
  font-family: Inter;
  font-weight: 400;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.45);
}
.new_wrapper .vet-calendar-steps .ant-steps-item-active .ant-steps-item-title {
  color: #ff9a05 !important;
}
.new_wrapper .vet-calendar-steps .footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new_wrapper .vet-calendar-steps .pet-name-input {
  width: 15.2rem;
}
.new_wrapper .vet-calendar-steps .stepper-footer {
  display: inline-flex;
  align-items: center;
}
.new_wrapper .vet-calendar-steps .stepper-footer .error-message {
  color: #ff4d4f;
  font-family: Inter;
  font-weight: 400;
  font-size: 1.4rem;
  margin-right: 1rem;
}
.new_wrapper .vet-calendar-steps .stepper-footer .back-btn {
  margin-right: 1.2rem;
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85);
}
.new-user-stepper-wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: '1250px') {
  .new_wrapper .vet-calendar-steps.booking-page {
    width: 100%;
    padding: 2.4rem 2rem;
  }
}
@media only screen and (max-width: '1080px') {
  .ant-steps-item-tail {
    display: none !important;
  }
  .new_wrapper .vet-calendar-steps .ant-steps-item {
    flex: 1 0 auto !important;
  }
  .new_wrapper .vet-calendar-steps {
    width: 100%;
  }
  .new_wrapper .vet-calendar-steps .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 7.4rem;
  }
  .new_wrapper .vet-calendar-steps .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: 7.4rem;
  }
  .new_wrapper .vet-calendar-steps .ant-picker-calendar-mini .ant-picker-calendar-header {
    float: right;
  }
  .new_wrapper .vet-calendar-steps .ant-steps-vertical {
    flex-direction: row !important;
  }
  .new_wrapper .vet-calendar-steps .vet-calendar-steps .ant-steps-item,
  .new_wrapper .vet-calendar-steps .ant-steps-item-container {
    display: inline-flex;
  }
  .new_wrapper .vet-calendar-steps .ant-steps-item-wait .ant-steps-item-content {
    display: none !important;
  }
  .new_wrapper
    .vet-calendar-steps
    .vet-calendar-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    margin-right: 0.8rem;
  }
  .new_wrapper .vet-calendar-steps .pet-name-input {
    width: 13.9rem;
    margin-left: 0.8rem;
  }
  .new_wrapper .vet-calendar-steps .icon-wrapper {
    padding-left: 1.5rem !important;
  }
  .new_wrapper .vet-calendar-steps .btn-create {
    margin-bottom: 3.6rem !important;
  }
  .new_wrapper .vet-calendar-steps .patient-details-col {
    width: 100%;
  }
  .ant-steps-item-finish .ant-steps-item-tail,
  .ant-steps-item-finish .ant-steps-item-title {
    display: none !important;
  }
}
.note-modal {
  width: 36.5rem;
  background: #fff;
  box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);
  border-radius: 0.8rem;
  padding: 2.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
}
.note-modal .d-flex {
  display: flex;
}
.note-modal h3 {
  margin-left: 1.6rem;
}
.note-modal p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #36517e;
  margin: 1.6rem 0 1.2rem;
}
.note-modal button {
  max-width: 10.3rem;
  max-height: 5.9rem;
  color: #fff;
}
@media (max-width: 768px) {
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: auto !important;
  }
}
