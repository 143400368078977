.ml24 {
  margin-left: 24px
}
.mr24 {
  margin-right: 24px
}
.short {
  max-width: 300px;
  cursor: default;
}

.client_name {
  cursor: pointer;
}

.address {
  cursor: pointer;
}
.address:hover {
  text-decoration: underline;
}
