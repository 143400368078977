.checkBoxGroup {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.checkBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 0.7;
  cursor: pointer;
}
.checkBox:hover, .checkBoxSelected {
  border: 1px solid #22a3c3;
  opacity: 1;
  background: #22a3c314;
}
