.submit-btn .ant-btn-primary {
  background-color: #22a3c3 !important;
  border-color: #22a3c3 !important;
  width: 6.7rem;
  height: 4rem;
  margin: 0 auto;
}

.submit-btn .ant-btn-primary:hover {
  background-color: #7abacc !important;
  border-color: #7abacc !important;
}

.submit-btn .ant-form-item-control-input-content {
  display: flex !important ;
  align-items: center !important;
}
