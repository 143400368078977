.vet-phone-input-wrapper .form-control {
  width: 100% !important;
  border-radius: 0rem;
  background-color: #fff !important;
  height: 32px !important;
  padding: 0px 45px !important;
}

.vet-phone-input-wrapper .form-control:disabled {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.vet-phone-input-wrapper .form-control:disabled,
.vet-phone-input-wrapper .form-control:disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
}

.vet-phone-input-wrapper .form-control::placeholder {
  color: #c5bfcd;
}

.vet-phone-input-wrapper .form-control:focus {
  border: 1px solid #4faafe;
}

.vet-phone-input-wrapper .flag-dropdown {
  background-color: #fafafa !important;
}

.vet-phone-input-wrapper .flag-dropdown:focus {
  border: 1px solid #4faafe;
}

.vet-phone-input-wrapper {
  flex: 1;
}

.modal-phone-input-wrapper {
  margin-top: 0.9rem;
}

.phone-input-info > svg {
  fill: #bfbfbf;
}

.phone-input-info:hover > svg {
  fill: #434343;
  cursor: pointer;
}

.popover-title {
  width: 25.6rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.ant-popover-inner-content {
  padding: 0.8rem !important;
}

.vet-phone-input-wrapper .react-tel-input {
  height: 32px !important;
}

.vet-phone-input-wrapper .label {
  margin-bottom: 0rem;
}

.vet-phone-input-wrapper .country {
  display: flex !important;
  align-items: center !important;
}

.vet-phone-input-wrapper .country-name {
  margin-left: 3rem !important;
}

.vet-phone-input-wrapper .flag {
  margin-top: 0.5 !important;
}
