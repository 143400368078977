.popover {
  display: flex;
  flex-direction: column;
}
.addInfo {
  font-style: italic;
  text-decoration: underline;
}
.addInfo:hover {
}

.singlePopover {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  line-height: 32px;
  margin-bottom: 0.8rem;
  justify-content: space-between;
}

.singlePopOverObject{
  margin-left: 5px;
  background: none;
  border: none;
  padding: 0;
}


.itemWithOutCopyIcon{
  padding-right: 25px;
}

.itemName{
  margin-right:20px
}

a:hover {
  color: #22a3c3;
}
