.tag {
  padding: 0 8px;
  color: var(--color-blue) !important;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important; /* 183.333% */
  border-radius: 2px !important;
  background: var(--color-black-1-op-09) !important;
  border-color: var(--color-blue) !important;
  margin-right: 0 !important;
}

.gray {
  color: var(--character-title-85) !important;
  background: var(--neutral-2, #fafafa);
  border-color: var(--neutral-5, #d9d9d9) !important;
}
.editable {
  cursor: pointer;
}
