.saveModal {
  white-space: pre;
}
.saveModalContent {
  display: flex;
  flex-direction: column;
  text-wrap: wrap;
  max-width: 600px;

}
.saveModalOptions {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  gap: 8px;
}

.input {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}
