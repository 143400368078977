.type {
  display: flex;
  flex-direction: column;
}
.type > span {
  margin-bottom: 16px;
}
.type .icon {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typeItem {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  margin-bottom: -1px;
}
.typeItem.selected {
  border-color: var(--color-blue);
  background-color: var(--color-blue-light);
}
.typeItem:hover {
  border-color: var(--color-blue);
}
.typeItem .type_column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}
