.container {
  min-height: 300px;
  min-width: 300px;
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  min-height: 400px;
  overflow: hidden;
}
.map :global(button[aria-label="Toggle fullscreen view"]) {
  height: 32px !important;
  width: 32px !important;
  border: 1px solid var(--color-gray-D9) !important;
  box-shadow: unset !important;
}

.areas {
  display: flex;
  gap: 12px;
}
.areas .row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
}

.input {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 32px;
  padding: 6px 12px;
  border: 1px solid var(--color-gray-D9);
  border-radius: 2px;
  margin-top: 10px;
  width: 200px;
  left: unset !important;
  right: 47px;
  display: none;
}

.fullScreenAreaList {
  width: 80%;
  left: 10%;
  background-color: var(--color-white);
  padding: 16px;
  position: fixed;
  bottom: 20px;
}

.otherAreasButton {
  margin-left: 10px;
  margin-top: -60px;
  padding-bottom: 30px;
}

.otherAreasButtonFS {
  left: 10px;
  width: 30%;
  position: fixed;
  bottom: 35px;
}

.gmapButton {
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 0px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  min-width: 40px;
  border-radius: 0px !important;
  height: 30px !important;
  font-size: 16px !important;
  padding: 6px 10px !important;
  box-shadow: unset !important;
}
.gmapButton:hover {
  background: none padding-box rgb(235, 235, 235);
}
