.container {
  background-color: var(--color-white);
  box-shadow: 0px 4px 45px -31px var(--color-shadow);
  border-radius: 8px;
  padding: 24px;
  padding-top: 0;
  width: 69%;
  overflow-y: auto;
  height: max-content;
  max-height: 85vh;
}
@media screen and (max-width: 1000px) {
  .container {
    margin-top: 16px;
    width: 100%;
    max-height: 100%;
    overflow-y: inherit;
  }
}

.headerSticky {
  top: 0;
  z-index: 1;
  padding-top: 24px;
  padding-bottom: 18px;
  background-color: #FFF;
  position: sticky;
}
.header {
  background-color: #FFF;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createButton {
  border: 1px solid #1890ff;
}
