
.buttonCreate {
  width: 100% !important;
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

.container {
  /*width: 516px;*/
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.buttonBack {
  font-size: 14px;
  margin-right: 8px;
}

.inputTitle {
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem !important;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  margin-bottom: 0 !important;
}

.inputFullWidth {
  width: 100% !important;
}

.hr {
  opacity: 40%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.workingAreaText {
  display: flex;
  justify-content: flex-start;
}

.createNewArea {
  border: 1px dashed #22a3c3;
}

.saveButton {
  border: 1px solid #1890ff;
}

.inputMarginBottom16 > div > div {
  margin-bottom: 16px;
}

.inputMarginBottom12 > div > div {
  margin-bottom: 12px;
}

.inputMarginBottom0 > div > div {
  margin-bottom: 0px;
}

.defaultPointsCheckbox {
  align-items: 'center';
  margin-bottom: 8px;
}

.pointsInput {
  display: flex;
  gap: 8px;
  align-items: 'center';
  margin-bottom: 24px;
}

.includeCheckbox {
  margin-bottom: 24px;
}

.formRow {
  display: flex;
  gap: 8px;
}

.formItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-bottom: 12px;
}
.repeatType {
  flex: 3;
}
.rowWeekly {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.inputRepeat {
  width: 125px;
}

.containerClass {
  padding-top: unset !important;
  height: 220px !important;
  min-height: 220px !important;
}

.mapClass {
  min-height: 200px !important;
}

.validationModal {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputContainer {
  min-height: 55px !important;
}
