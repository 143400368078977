@import '../../../common_repo/styles/mixins';

.edit-appointment-address-wrapper{
  width : 37.4rem;
  padding : 0 2.2rem 2.3rem 2.4rem;
  @include sm{
    width: 100%;
  }
  .edit-appointment-address__header{
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    svg {
      width: 2rem;
      height: 2.4rem;
      margin-left: 0;
    }
    h2{
      color: rgba(0, 0, 0, 0.85);
      margin-left: 1.8rem;
      font-family: Inter;
      font-style: normal;
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  .edit-appointment-address__footer{
    display: flex;
    margin-top: 2.4rem;
    justify-content: flex-end;
  }
  .additional-info, h3{
    font-weight: 400!important;
    opacity: 0.8;
  }
  .ant-form-item{
    margin-bottom: 1.4rem;
  }
}

/*======= Map Styling ============*/
//.gmnoprint,.gmnoprint a, .gmnoprint span {
//  display:none;
//}
//.gmnoprint div {
//  background:none !important;
//}
//#GMapsID div div a div img{
//  display:none;
//}

.gmnoprint[role="menubar"] {
  display: none;
}
.gmnoprint[role="menubar"].gm-style-mtc-bbw {
  display: block;
  box-shadow: unset !important;
  border: 1px solid var(--color-gray-D9) !important;
  border-radius: 2px !important;
  button {
    border-radius: 0px !important;
    height: 30px !important;
    font-size: 16px !important;
    padding: 5px 8px !important;
    box-shadow: unset !important;

  }
  ul {
    font-size: 16px !important;
    padding: 0px !important;
    margin: 0px !important;
    top: 32px !important;
    border: 1px solid var(--color-gray-D9) !important;
    border-radius: 2px !important;
    box-shadow: unset !important;
    li {
      box-shadow: unset !important;
      padding: 5px 7px !important;
      height: 32px !important;
      font-size: 16px !important;
    }
  }
}
.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  right: 32px !important;
  bottom: 72px !important;
  box-shadow: unset !important;
  border: 1px solid var(--color-gray-D9) !important;
  border-radius: 2px !important;
  button {
    height: 32px !important;
    width: 32px !important;
  }
  div:last-child:first-child {
    height: 64px !important;
    width: 32px !important;
  }
}
