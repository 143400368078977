.container {
  display: flex;
  padding: 32px 42px;
  width: 100%;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: space-between;
  margin-top: 24px;
}

.column {
  min-width: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.description {
  color: var(--color-black-op-64);
}

.addButton {
  margin-top: 64px !important;
  min-width: 180px;
}

@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
    justify-content: unset;
  }
  .column {
    min-width: unset;
  }
  .addButton {
    flex: 1;
    width: 100% !important;
    margin-top: 32px !important;
  }
}
