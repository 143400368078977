.address-input-info > svg {
  fill: #bfbfbf;
}

.address-input-info:hover > svg {
  fill: #434343;
  cursor: pointer;
}

.address-info-icon{
  color : #BFBFBF!important;
}
.address-info-icon:hover{
  color: black!important;
}
.address-info-icon svg {
  font-size: 1.2rem !important;
}
.custom-error-message{
  margin-top: 1rem;
  color: #FF4B55;
}