.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 2px;
  background-color: rgba(138, 148, 166, 0.16);
}
.bg {
  background-color: var(--color-blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.sm {
  height: 16px;
  width: 16px;
  min-width: 16px;
  font-size: 8px;
  line-height: 8px;
}
.md {
  height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 10px;
  text-align: center;
  line-height: 10px;
}
.lg {
  height: 32px;
  width: 32px;
  min-width: 32px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
}
.xl {
  height: 64px;
  width: 64px;
  min-width: 64px;
  font-size: 32px;
  line-height: 32px;
}
.container span {
  color: var(--color-white);
}
