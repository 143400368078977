.actionColumn {
  display: flex;
  align-items: center;
}
.actionItem {
  width: 180px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--color-112950);
}

.actionColumnDropdown {
  display: flex;
  gap: 12px;
  padding: 0 16px;
  align-items: center;
}

.iconRemove svg {
  color: red;
}

.dropDown :global(.ant-dropdown-menu) {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.dropDown :global(.ant-dropdown-menu-item) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
