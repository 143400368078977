.form {
  width: 100%;
}
.input {
  margin-top: 10px;
}
.input:first-child {
  margin-top: 0;
}
.buttonWrapper {
  margin-top: 3rem;
}
.commonError {
  display: block;
  font-family: inter,assistant;
  font-weight: 400;
  height: 1rem;
  color: #E44E61;
  font-size: 1.2rem;
}
