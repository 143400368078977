.gap8 { gap: 8px; }
.gap12 { gap: 12px; }
.gap16 { gap: 16px; }
.mt4 { margin-top: 4px !important;}
.mt5 { margin-top: 5px !important;}
.gap3 { gap: 3px; }
.mt8 { margin-top: 8px; }
.mt12 { margin-top: 12px; }
.mt16 { margin-top: 16px; }
.mb4 { margin-bottom: 4px; }
.mb8 { margin-bottom: 8px; }
.mb10 { margin-bottom: 10px; }
.mb12 { margin-bottom: 12px; }
.mb16 { margin-bottom: 16px; }
.mb18 { margin-bottom: 18px; }
.mb20 { margin-bottom: 20px; }
.mb24 { margin-bottom: 24px; }
.mb32 { margin-bottom: 32px; }
.mr2 { margin-right: 2px; }
.mr4 { margin-right: 4px; }
.mr8 { margin-right: 8px; }
.mr12 { margin-right: 12px; }
.mr16 { margin-right: 16px; }
.ml8 { margin-left: 8px; }
.ml12 { margin-left: 12px; }
.ml16 { margin-left: 16px; }
.w100 { width: 100%; }
.w100i { width: 100% !important; }
.f1 { flex: 1; }
.pl32 { padding-left: 32px; }
.p8 { padding: 8px; }
.jcSB { justify-content: space-between; }
.wrap {
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}
