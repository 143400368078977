.user {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.user > span {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--character-title-85);
}
.user .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #22a3c3;
  border-radius: 50%;
  background-color: #fff;
}
.icon img {
  object-fit: contain;
}
.userName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
