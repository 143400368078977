.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-white);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hr {
  width: calc(100% + 48px);
  height: 1px;
  background-color: var(--color-gray-bg);
  margin: 12px -24px;
}

.controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 650px) {
  .row {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .controls {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .controls button {
    min-width: 100%;
  }
  .controls :global(.ant-select) {
    width: 100%;
  }
}
