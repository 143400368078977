.popover {
}
.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popover :global(.ant-popover-content .ant-popover-arrow .ant-popover-arrow-content) {
  background-color: rgba(0,0,0,0.8) !important;
  --antd-arrow-background-color: unset !important;
  border-color: unset;
}
.popover :global(.ant-popover-inner) {
  background-color: unset !important;
}
.popover :global(.ant-popover-inner-content) {
  background-color: rgba(0,0,0,0.8) !important;
  max-width: 400px;
  min-width: 250px;
  color: var(--color-white);
}

.row {
  display: flex;
  align-items: center;
}
.row span:first-child {
  flex: 1;
}
.row span:last-child {
  width: 150px;
  text-align: right;
}
