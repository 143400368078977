.container {
  display: flex;
  gap: 32px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 2;
}

.control {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.places {
  flex: 4;
  display: flex;
  flex-direction: column;
}

.validationModal {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.zipTitle {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 5px;
}

.zipMapContainer {
  height: 320px;
  margin-bottom: 16px;
}

.zipMap {
  min-height: 320px !important;
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
  }
}
@media screen and (min-width: 400px) {
  .modalContainer {
    min-width: 400px;
  }
}
@media screen and (min-width: 600px) {
  .modalContainer {
    min-width: 500px;
  }
}
@media screen and (min-width: 800px) {
  .modalContainer {
    min-width: 650px;
  }
}
@media screen and (min-width: 1000px) {
  .modalContainer {
    min-width: 800px;
  }
}
@media screen and (min-width: 1200px) {
  .modalContainer {
    min-width: 950px;
  }
}
