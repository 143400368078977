.modal {
  min-width: 380px;
}

.modalContent {
  min-height: 300px;
  max-height: 50vh;
  overflow-y: auto;
}

.button {
  flex: 1;
}

.selectable {
  cursor: pointer;
  padding: 8px;
  width: 100%;
  border: 1px solid var(--color-gray-ced4d9);
  border-radius: 2px;
  margin-bottom: 5px;
}
.selectable:last-child {
  margin-bottom: unset;
}
.selectable > div > span {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-black);
}
.selectable:hover {
  border-color: var(--color-blue);
  outline: 2px solid rgba(34, 163, 195, 0.1215686275);
}

.active {
  border-color: var(--color-blue);
}
