.container {
  position: relative;
  display: flex;
  border-radius: 3px;
  border: 1px solid var(--color-gray-D9);
  padding: 10px 16px;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.selected {
  border: 1px solid var(--color-blue);
  box-shadow: 0px 0px 4px 0px var(--color-blue-dark);
}
.container:hover {
  opacity: 0.8;
}
.selectedMark {
  position: absolute;
  right: -8px;
  top: -8px;
  color: var(--color-blue);
  font-size: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 50%;
}
.avatarWrapper {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 2px solid var(--color-solitude-e8);
  padding: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.avatar {
  width: 100%;
  border-radius: 50%;
}
.pet {
  display: flex;
  flex-direction: column;
}
.name {
  color: var(--color-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.description {
  color: var(--color-gray-99);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.button {
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
}
.buttonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
