.container {
  min-width: 400px;
  display: flex;
  width: 100%;
}
.duration {
  text-align: right;
  margin-top: 24px;
}
.avatarWrapper {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 2px solid var(--color-solitude-e8);
  padding: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.avatar {
  width: 100%;
  border-radius: 50%;
}

.cl {
  z-index: 1010101010;
}

.petCount {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 2px solid var(--color-solitude-e8);
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue);
  color: var(--color-white);
}
