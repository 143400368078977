.container {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  background-color: var(--color-white);
}
.header .headerCaregivers {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 700px) {
  .header {
    gap: 10px;
    flex-direction: column;
  }
  .header .headerCaregivers {
    flex-direction: column;
  }
  .header > *, .header > button {
    width: 100% !important;
  }
}

.timeslotsWrapper {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.calendar, .timeSlots {
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 12px 24px 12px 24px;
}

.calendar {
  flex: 1;
  min-width: 380px;
}

.calendarWidth {
  width: unset;
}

.timeSlots {
  flex: 5;
  min-width: 430px;
}

@media screen and (max-width: 700px) {
  .timeslotsWrapper {
    flex-direction: column;
  }
  .timeSlots, .calendar {
    min-width: unset;
    width: 100%;
  }
}
.apps {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.modal :global(.ant-modal-header) {
  border-bottom: unset !important;
}

.modal :global(.ant-modal-footer) {
  padding: 0.8rem 1.6rem 1rem 1.6rem !important;
}

.modal :global(.ant-modal-body) {
  padding: 3.2rem 4rem 0rem 4rem !important;
}

.modal :global(.ant-modal) {
  top: 2.5rem;
}

.skeletonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.skeletonContainer .row {
  display: flex;
  align-items: center;
  gap: 20px;
}
.skeletonContainer .item1 {
  border-radius: 8px;
  width: 100%;
  flex: 10;
  height: 56px;
}
.skeletonContainer .item2 {
  border-radius: 8px;
  width: 100%;
  flex: 2;
  height: 56px;
}
.skeletonContainer .section2 {
  margin-top: 20px;
}
.skeletonContainer .section2 .item3 {
  border-radius: 8px;
  width: 100%;
  height: 380px;
  flex: 1;
  min-width: 380px;
}
.skeletonContainer .section2 .item4 {
  border-radius: 8px;
  width: 100%;
  height: 380px;
  flex: 5;
  min-width: 430px;
}
.skeletonContainer .section3 {
  margin-top: 20px;
}
.skeletonContainer .section3 .item5 {
  border-radius: 8px;
  width: 100%;
  height: 560px;
}

.warningIcon {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.warningIcon > svg {
  color: #FF4D4F;
  background-color: white;
  border-radius: 50%;
  border: 1px solid white
}

.dateContainer {
  position: relative;
  text-align: center;
  width: 20px;
}
