.item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 246px;
  height: 220px;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);
}

.header {
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--neutral-1, #FFF);
}

.integration {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageWrapper {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--neutral-1, #FFF);
}

.integration img {
  width: auto;
  height: 18px;
  object-fit: contain;
}

.text {
  margin-left: 8px;
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.content {
  padding: 16px 16px;
  background: var(--neutral-1, #FFF);
  box-shadow: 0px 1px 0px 0px #F0F0F0 inset;
  flex: 1;
}

.content > span {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.content .row {
  display: flex;
  flex-direction: row;
}

.content .row > span {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer {
  padding: 12px 16px;
  background: var(--neutral-1, #FFF);
  box-shadow: 0px 1px 0px 0px #F0F0F0 inset;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.footer .button {
  text-align: center;
  padding: 0 8px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.itemDisabled {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.itemDisabled > span {
  margin-top: 12px;
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

/* icons */
.iconOutlined * {
  color: rgb(34, 163, 195);
  margin-left: 12px;
}

.item:hover {
  border: 1px solid #22A3C3;
}

.item:hover .itemDisabled > div {
  background: #22A3C3;
}
