.container {
  position: relative;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.avatarWrapper {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 2px solid var(--color-solitude-e8);
  padding: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.avatar {
  width: 100%;
  border-radius: 50%;
}
.pet {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}
.description {
  color: var(--color-gray-99);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.button {
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
}
.buttonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
