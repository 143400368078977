.errorPopoverContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 250px;
}

.errorPopoverButton {
  align-self: end;
}

.errorPopover :global(.ant-popover-inner-content){
  color: white !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.errorPopover :global(.ant-popover-arrow-content){
  --antd-arrow-background-color: inherit;
}
