.content {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.header {
  display: flex;
  align-content: center;
  align-items: center;
}
.header svg {
  width: 22px;
  height: 22px;
}
.header svg * {
  fill: #FF9A05;
}
.header span {
  margin-left: 16px;
}
.desc {
  margin-top: 8px;
  margin-left: 38px;
  display: flex;
  flex-direction: column;
}

.desc ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}
.footer {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 8px;
}

.footer .btnCancel {
  width: 100px;
  border-radius: 0;
}
.footer .btnOk {
  width: 130px;
  border-radius: 0;
  background-color: #FF9A05!important;
  border-color: #FF9A05 !important;
  margin: 0 !important;
}
