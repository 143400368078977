.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  position: absolute;
  z-index: 500;
  box-shadow: inset 0px 0px 5px 5px #fffdf8;
}
.noShadow {
  box-shadow: unset;
}

.image {
  border-radius: 50%;
  width: 5.6em;
  height: 5.6em;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  font-size: x-small !important;
}
.small .image, .small .image svg {
  width: 4.2em !important;
  height: 4.2rem !important;
}
.image svg {
  cursor: default;
}

.md {
  position: inherit;
  min-height: 350px;
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
