.userID {
  cursor: pointer;
}

.actions {
  display: flex;
  gap: 0.7rem;
}

.actionButtons {
  cursor: pointer;
  user-select: none;
}
