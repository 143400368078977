.imageWrapperDisabled {
  height: 48px;
  width: 48px;
  background: #59616E;
}

.imageWrapper img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.iconAntDesignOutlined * {
  color: #FFF;
  cursor: default;
}

.imageWrapperDisabled, .imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
