.container {
  min-height: 300px;
  min-width: 300px;
  width: 100%;
  height: 100%;
  padding-top: 16px;
}

.map {
  width: 100%;
  min-height: 65vh;
  overflow: hidden;
}
.map :global(button[aria-label="Toggle fullscreen view"]) {
  height: 32px !important;
  width: 32px !important;
  border: 1px solid var(--color-gray-D9) !important;
  box-shadow: unset !important;
}

.areas {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
}

.input {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 32px;
  padding: 6px 12px;
  border: 1px solid var(--color-gray-D9);
  border-radius: 2px;
  margin-top: 10px;
  width: 200px;
  left: unset !important;
  right: 47px;
  display: none;
}

.popup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.popupTitle {
  position: absolute;
  font-size: 2rem;
  top: 10px;
}

.popupContainer {
  display: flex;
  overflow: auto;
  max-height: 600px;
  flex-direction: column;
  width: 100%;
}
.popupContainer .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popupContainer .area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.popupContainer .square {
  width: 12px;
  height: 12px;
}
.popupContainer .polygons {
  display: flex;
  padding-left: 22px;
  align-items: start;
  gap: 10px;
}
.popupContainer .polygons > span {
  color: var(--character-secondary-45);
}
.popupContainer .polygons .polygonsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 4px;
  max-width: 240px;
}

.popupArea {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 2px;
  border: 1px solid var(--color-gray-F0F0F0);
}
