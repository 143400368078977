.section {
  flex-direction: column;
  margin-top: 40px;
  display: none;
}

.label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.visible {
  display: flex;
}
