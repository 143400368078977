.modal {
  min-height: 100px;
}
@media only screen and (min-width: 1000px) {
  .modal {
    min-width: 500px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}
.header h3 {
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}
.content,
.header {
  padding: 16px 24px;
}
