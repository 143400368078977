.container {
  background-color: var(--color-white);
  box-shadow: 0px 4px 45px -31px var(--color-shadow);
  border-radius: 8px;
  padding: 24px;
  width: 29%;
  overflow-y: auto;
  height: max-content;
  max-height: 50vh;
}
@media screen and (max-width: 1000px) {
  .container {
    width: 100%;
    overflow-y: unset;
    max-height: unset;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.caregiverCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  height: 48px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
}

.caregiverCardSelected {
  border: 1px solid #22a3c3;
  opacity: 1;
}
