.button {
  border-width: 1px;
  border-radius: 2px;
  width: fit-content !important;
  margin: 0 !important;
}

/*region primary*/
:global(.ant-btn-primary).colorSchemeOrange {
  border-color: var(--color-orange) !important;
  background-color: var(--color-orange) !important;
  color: var(--color-white) !important;
}

:global(.ant-btn-primary).colorSchemeOrange:hover {
  border-color: var(--color-orange-hover) !important;
  background-color: var(--color-orange-hover) !important;
  color: var(--color-white) !important;
}

:global(.ant-btn-primary).colorSchemeBlue {
  border-color: var(--color-blue) !important;
  background-color: var(--color-blue) !important;
  color: var(--color-white) !important;
}

:global(.ant-btn-primary).colorSchemeGray {
  border-color: var(--color-gray-D9) !important;
  background-color: var(--color-gray-D9) !important;
  color: var(--color-black) !important;
}

/*endregion primary*/
/*region secondary*/
:global(.ant-btn-secondary).colorSchemeOrange {
  border-color: var(--color-orange) !important;
  background-color: var(--color-white) !important;
  color: var(--color-orange) !important;
}

:global(.ant-btn-secondary).colorSchemeBlue {
  border-color: var(--color-blue) !important;
  background-color: var(--color-white) !important;
  color: var(--color-blue) !important;
}

:global(.ant-btn-secondary).colorSchemeBlueLight {
  border-color: var(--color-blue) !important;
  background-color: var(--color-blue-light) !important;
  color: var(--character-title-85) !important;
}

:global(.ant-btn-secondary).colorSchemeGray {
  border-color: var(--color-gray-D9) !important;
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
}

/*endregion secondary*/
/*region default*/
:global(.ant-btn-default).colorSchemeBlueLight {
  background-color: var(--color-white) !important;
  color: var(--character-title-85) !important;
  opacity: 0.7;
}

/*endregion default*/
:global(.ant-btn-primary[disabled]) {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

:global(.ant-btn-primary).colorSchemeBlue:hover {
  border-color: var(--color-blue-hover) !important;
  background-color: var(--color-blue-hover) !important;
  color: var(--color-white) !important;
}

:global(.ant-btn-primary[disabled]):hover {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: var(--color-gray-D9) !important;
  background: var(--color-gray-F5) !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

:global(.ant-btn-primary[disabled]).colorSchemeOrange {
  border-color: var(--color-orange-disabled) !important;
  background-color: var(--color-orange-disabled) !important;
  color: var(--color-white) !important;
}

:global(.ant-btn-primary[disabled]).colorSchemeBlue {
  border-color: var(--color-blue-disabled) !important;
  background-color: var(--color-blue-disabled) !important;
  color: var(--color-white) !important;
}

:global(.ant-btn[disabled]) {
  cursor: default !important;
}
