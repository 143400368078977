@import '../../common_repo/styles/variables';

.calendar-wrapper {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: #e5e5e5;
  padding: 1.6rem 7.7rem;
  position: relative;

  #masthead {
    height: 70px;
    position: fixed;
    width: 100%;
    top: 0;
  }
  .auth-user-wrapper {
    display: inline-flex;
    align-items: center;
    h3 {
      font-family: Inter;
      font-weight: 700;
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.85);
      opacity: 0.8;
    }
    h4 {
      font-family: Inter;
      font-size: 1.4rem;
      font-weight: 400;
      margin-left: 0.4rem;
      margin-bottom: 0;
    }
    img {
      width: 1.6rem;
      height: 1.6rem;
      object-fit: cover;
      border-radius: 50%;
      margin: 0 0.4rem;
    }
  }
  .calendar-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(24.8rem + 1.6rem + 77.4rem);
    margin: auto;
    h3 {
      font-family: Inter;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
    .auth-title-wrapper {
      display: flex;
      align-items: center;
      h3 {
        font-size: 14px;
      }
      a {
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #22a3c3;
        margin-left: 1.6rem;
      }
    }
  }
  .calendar-content-wrapper {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    max-width: calc(24.8rem + 1.6rem + 77.4rem);
    margin: 1.3rem auto;
    flex-wrap: wrap;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #000000;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
.log-out-wrapper {
  width: 9.6rem;
  height: 4rem;
  background: #ffffff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  h3 {
    margin-left: 1rem;
    font-family: Inter;
    font-size: 1.4rem;
    font-weight: 400;
  }
}
@media only screen and (max-width: $medium) {
  .calendar-wrapper {
    padding: 1.6rem 0.6rem;
    height: auto !important;
    padding-bottom: 100px;
  }
  .calendar-wrapper.booking {
    padding-bottom: 2.7rem !important;
  }
}
@media only screen and (max-width: $tablet) {
  .calendar-header-wrapper {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .vet-side-title {
      width: 100%;
      text-align: left;
    }
    .auth-user-wrapper,
    .auth-title-wrapper {
      justify-content: flex-start;
      width: 100%;
    }
  }
  .calendar-container {
    padding-bottom: 80px;
  }
}
