.container {
  display: grid;
  gap: 24px;
  width: 100%;
  align-content: start;
  grid-template-columns: repeat(auto-fill, minmax(180px, 5fr));
}

.marker {
  visibility: hidden;
}
