@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: auto;
  src:
    local(Inter),
    url('./fonts/Inter-Thin.ttf') format('trueType');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src:
    local(Inter),
    url('./fonts/Inter-Light.ttf') format('trueType');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src:
    local(Inter),
    url('./fonts/Inter-Regular.ttf') format('trueType');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src:
    local(Inter),
    url('./fonts/Inter-Medium.ttf') format('trueType');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src:
    local(Inter),
    url('./fonts/Inter-SemiBold.ttf') format('trueType');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src:
    local(Inter),
    url('./fonts/Inter-Bold.ttf') format('trueType');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src:
    local(Inter),
    url('./fonts/Inter-Black.ttf') format('trueType');
}
