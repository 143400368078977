.container {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  align-items: center;
  gap: 4px
}
.rowLabel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.rowLabel > svg {
  width: 20px;
  height: 20px;
}
.select {
  width: 150px;
}
.input {

}
.error {

}
