.container {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: var(--color-wh-gr);
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.petRow {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 8px;
}

.tag {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagEditable:hover {
  opacity: 0.8;
  border-color: var(--color-blue) !important;
}

.travelingTime {
  padding: 0 10px;
}

.line {
  flex: 1;
  height: 1px;
  background-color: var(--color-wh-f5f6f9);
}

.mt16 {
  margin-top: 16px;
}

.etaTitle {
  gap: 8px;
}

.info {
  color: var(--neutral-6) !important;
  font-size: 14px;
}

.info:hover,
.more:hover {
  color: var(--color-black) !important;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.headerMenu {
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.duration {
  cursor: pointer;
  width: 55px !important;
}

.duration:hover {
  text-decoration: underline;
}

.panel {
  margin-top: 8px;
  padding: 10px 16px;
  width: 100%;
  background-color: var(--color-white);
  cursor: default !important;
}

.panel:first-child {
  margin-top: 0;
}

.collapse :global(.ant-collapse-item) {
  border-radius: 3px !important;
}

.collapse :global(.ant-collapse-header) {
  padding: 0 !important;
}

.hr {
  margin-left: -64px;
  margin-top: 24px;
  margin-bottom: 24px;
  width: calc(100% + 96px);
  height: 1px;
  background-color: var(--color-gray-F0F0F0, #f0f0f0);
}

.files {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.file {
  color: unset;
  text-decoration: none;
}

.fileIcon {
  font-size: 34px;
}

.selectConcern {
  width: 120px;
}

.editable {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: var(--color-gray-F5);
}

.editableContainer {
  width: 70%;
  background-color: var(--color-gray-F5);
}

@media screen and (max-width: 700px) {
  .editableContainer {
    width: 100%;
  }
}

.textarea {
  resize: none !important;
  text-decoration: unset !important;
  box-shadow: unset !important;
  border: unset !important;
  margin-left: -8px !important;
  width: calc(100% + 16px) !important;
  max-width: unset !important;
  min-height: 100px !important;
  background-color: unset !important;
  padding: 0 !important;
}

.dragging {
  margin-right: 8px;
  color: var(--color-royal-primary, #2d3363);
  font-size: 16px;
}

.fileRow {
  position: relative;
}

.fileRemove {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  color: var(--color-gray-B8);
  background-color: #fff;
}

.fileRow:hover .fileRemove {
  display: block;
}

.tagRow {
  display: block;
  position: relative;
  cursor: pointer;
}

.tagRemove {
  display: none;
  position: absolute;
  font-size: 18px;
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  right: 2px;
  top: 3px;
  color: var(--color-gray-B8);
  background-color: #fff;
}

.tagRow:hover .tagRemove {
  display: flex;
}

.menuMoreIcon {
  color: var(--color-black) !important;
  padding: 4px;
  transition: 0.2s;
  border-radius: 50%;
  font-size: 20px;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuMoreIcon svg {
  margin: 0;
}

.menuMoreIcon:hover {
  background-color: var(--color-gray-D9);
}

.addButton {
  padding: 0 16px !important;
}

.addButton:hover {
  color: unset;
  border-color: var(--color-blue);
}

.commentTextArea {
  white-space: pre-wrap;
}

.originalScheduled {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
  .originalScheduled {
    max-width: 200px;
  }
  .petRowPanel {
  }
  .petRowPanelPet {
    align-items: flex-start;
    flex-direction: column;
  }
  .petRowPanelPet > div:last-child {
    padding-left: 16px;
  }
}

.warningIcon > svg {
  color: #ff4d4f;
}

.warningIcon {
  margin: 0 !important;
  align-self: center;
}

.originallyScheduledToButtonEdit {
  cursor: pointer;
  font-size: 14px;
  color: #112950 !important;
}

.originallyScheduledToButtonEdit:hover {
  color: #22a3c3 !important;
}
