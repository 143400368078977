.externalIdTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #BFBFBF;
}

.externalIdTitle > p {
  font-size: 14px;
  padding-bottom: 0.5rem;
  color: #000000D9;
}

.petModalIntegrationError {
  display: flex;
  align-items: center;
  color: #FF4D4F;
  max-width: 600px;
}

.petModalWarningIcon {
  margin-bottom: 1em;
  margin-right: 10px;
  align-self: flex-start;
  width: 20px;
  height: 20px;
}

.petModalWarningIcon > svg {
  width: 20px;
  height: 20px;
}

.externalIdActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.directLink {
  display: flex;
}

.directLink > svg {
  height: 14px;
  width: 14px;
}

.actionButton {
  color: #BFBFBF !important;
}

.actionButton:hover {
  color: #22a3c3 !important;
}
