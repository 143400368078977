.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.timeSlotCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  min-height: 74px;
  border: 1px solid var(--color-gray-F0F0F0);
  border-radius: 2px;
}

.opacity70 {
  opacity: 0.7;
}

.labels {
  flex: 7;

  display: flex;
  flex-direction: column;
  gap: 2px;
}

.timeAndAction {
  flex: 4;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.loader {
  max-height: 500px;
}
