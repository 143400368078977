@import './fonts.css';

:root {
  --color-black-3: #323b4b;
  --color-gray-ced4d9: #CED4D9;
  --color-green: #34c759;
  --color-orange: #ff9a05;
  --primary-6: #1b829c;
  --color-gray-F0F0F0: #f0f0f0;
  --color-gray-FAFAFA: #fafafa;
  --color-orange-hover: #ffb03e;
  --color-orange-light: #ffba53;
  --color-blue: #22a3c3;
  --color-blue-hover: rgba(34, 163, 195, 0.85);
  --color-blue-light: #eef8fb;
  --color-purple: #2d3363;
  --color-violet: #a68bff;
  --color-violet-2: #f8f6ff;
  --color-red: #ff4b55;
  --color-red-2: #fdf1f2;
  --color-blue-op-06: rgba(34, 163, 195, 0.06);
  --color-blue-dark: #24697b;
  --color-blue-contrasted: #00ccff;
  --color-gray-D9: #d9d9d9;
  --color-gray-F5: #f5f5f5;
  --color-gray-B8: #b8b8b8;
  --color-gray-99: #999999;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-black-1-op-09: rgba(172, 188, 212, 0.09);
  --color-solitude-e8: #e8ecf4;
  --character-secondary-45: rgba(0, 0, 0, 0.45);
  --character-title-85: rgba(0, 0, 0, 0.85);
  --color-wh-gr: #f1f4f8;
  --color-wh-f5f6f9: #f5f6f9;
  --color-black-o40: rgba(0, 0, 0, 0.4);
  --neutral-6: #bfbfbf;
  --color-black-434343: #434343;
  --color-8A94A6: #8a94a6;
  --color-323B4B: #323b4b;
  --color-orange-h: #faa426;
  --color-112950: #112950;
  --color-DBE2EE: #dbe2ee;
  --color-B0B7C3: #b0b7c3;
  --color-shadow: #2D1C1C14;
  --color-595959: #595959;

  --width-mobile: 480px;
  --width-laptop: 1368px;

  --color-orange-disabled: #ffce89;
}

.ant-tooltip .ant-tooltip-arrow {
  display: none !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--color-blue) !important;
  color: var(--color-blue) !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--color-blue) !important;
}

.ant-radio-inner::after {
  background-color: var(--color-blue) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-blue) !important;
  border-color: var(--color-blue) !important;
}

.ant-btn span {
  font-family: Inter;
}

.outdated-btn-modal {
  margin-left: 1.2rem !important;
  max-width: 10rem;
  min-width: 10rem !important;
  font-weight: 600;
}
