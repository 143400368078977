.container {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 32px 25px;
}
.tag {
  margin: 0 !important;
  visibility: hidden;
}
.full {
  visibility: unset;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerCounts {
  display: flex;
  align-items: baseline;
  gap: 20px;
}
@media screen and (max-width: 600px){
  .headerCounts {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .header {
    align-items: flex-start;
    gap: 10px;
  }
}
.picker {
  width: 100%;
}
.filters {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;
}

.cell {
  flex: 1;
}

.cellPopover {
  display: flex;
  flex-direction: column;
  width: auto;
}

.actionItem {
  width: 195px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--color-112950);
}
.iconRoute {
  cursor: default;
  width: 24px !important;
  height: 24px !important;
  margin: 0;
}
.iconRoute > * {
  fill: var(--color-blue);
}
.lockModal {
  max-width: 600px;
  min-width: 300px !important;
}
.generateModal {
  max-width: 500px;
  min-width: 300px !important;
}
.generateDescription {
  display: block;
}
.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.dropDown :global(.ant-dropdown-menu) {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.dropDown :global(.ant-dropdown-menu-item) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.actionColumnDropdown {
  display: flex;
  gap: 12px;
  padding: 0 16px;
  align-items: center;
}
.locked > * {
  fill: var(--color-red);
}
.iconRemove svg {
  color: var(--color-red);
}
.actionColumn {
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
}

.lockIcon {
  display: flex;
  align-items: baseline;
}

.lockIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
}

.actions {

}

.errorPopoverContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 250px;
}

.errorPopover :global(.ant-popover-inner-content){
  color: white !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.errorPopover :global(.ant-popover-arrow-content){
  --antd-arrow-background-color: inherit;
}

.errorPopoverButton {
  align-self: end;
}

.petIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.errorPetIconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgb(87, 87, 87);
  background-color: black;
}

.errorPopoverPetsContent {
  min-width: 200px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.waringPetIcon > svg {
  background-color: white;
  color: #FF4D4F;
  position: absolute;
  left: 20px;
  top: -5px;
  outline: 1px solid white;
  border-radius: 50%;
}

.fullNameTitle {
  padding-left: 23px;
}

.warningIntegrationIcon {
  height: 18px;
  padding-right: 5px;
}

.warningIntegrationIcon > svg {
  color: #FF4D4F;
  width: 16px;
  height: 16px;
}

.petsRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullNameRow {
  display: flex;
  align-items: center;
}

.hiddenIntegrationIcon {
  visibility: hidden;
}

.syncStatusDropdown {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 5px 0 5px;
  transition: border 0.3s, box-shadow 0.3s;
}

.syncStatusDropdown:hover {
  border-color: #1890ff;
}

.syncFilterLabel {
  text-align: center;
  width: 10rem;
}

.syncFilterArrow {
  width: 14px;
  height: 14px;
}

.syncFilterArrow svg {
  transition: transform 0.2s ease;
}

.petIntegrationCell {
  display: flex;
  align-items: center;
}
