.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.container .title {
  margin-top: 16px;
}
.container .description {
  margin-top: 24px;
  text-align: center;
}
.container .icon {
  font-size: 35px;
  color: var(--color-green);
}
.container .button {
  width: 100% !important;
  margin-top: 48px !important;
}

.modalContainer {
  max-width: 416px !important;
  min-width: unset !important;
}
