.container {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 32px 25px;
  overflow: auto;
  margin-bottom: 20px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
