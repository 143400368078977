@import '../../../../common_repo/styles/variables';

.address-step {
  margin-top: 4rem;
  justify-content: space-between;

  .calendar-in-the-center{
    max-width: 30rem;
    margin: auto;
  }
  .address-field {
    input {
      height: 3.2rem;
      border-radius: 0.2rem;
      padding: 0.4rem 3.5rem 0.4rem 1.2rem;
    }

    div {
      border-radius: 0;
    }

    label {
      display: none;
    }

    h3 {
      font-family: Inter;
      font-weight: 600;
      font-size: 1.6rem;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 1.2rem;
    }
  }

  .additional-info {
    font-family: Inter;
    font-weight: 600;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.85);
  }

  .property-type-wrapper{
    width: 281px;
    display: flex;
    gap: 1.2rem;
    margin-bottom: 1rem;
  }
  textarea {
    height: auto !important;
    resize: none;
    font-family: Inter;
    font-weight: 400;
    font-size: 1.4rem;
  }

  .address-calendar-wrapper {
    position: relative;

    .ant-select-selector{
      height: 3.2rem!important;
    }
    .choose-date.disabled {
      color: rgba(0, 0, 0, 0.85);
      opacity: 0.4;
    }

    .choose-date {
      font-family: Inter;
      font-weight: 600;
      font-size: 1.4rem;
      position: absolute;
      left: 0;
      top: 1.8rem;
    }

    .ant-picker-panel {
      border-top: 0;
    }

    .ant-picker-calendar-header {
      .ant-select-selection-item {
        padding-top: 0.4rem;
      }

      .ant-radio-group {
        display: none;
      }
    }

    .ant-picker-cell-disabled .ant-picker-calendar-date-value {
      color: #d9d9d9;
      font-weight: 400;
    }

    .ant-picker-calendar-date-value {
      color: #ff9a05;
      font-family: Inter;
      font-weight: 600;
    }

    .enabled-calendar {
      .ant-picker-cell-selected .ant-picker-calendar-date-value {
        border: 1px solid #ff9a05 !important;
        border-radius: 0.2rem;
        background: #ff9a05;
        color: #fff;
        font-family: Inter;
        font-weight: 600;
      }

      .ant-picker-cell-today .ant-picker-calendar-date-value {
        background: transparent;
        color: #141414;
        border: 1px solid #ff9a05 !important;
        border-radius: 0.4rem;
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: transparent !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: none !important;
    }
  }

  .disabled-calendar {
    .ant-picker-date-panel .ant-picker-content th {
      color: #d9d9d9 !important;
    }
  }
  .disabled-calendar .ant-select-selection-item {
    color: #d9d9d9 !important;
  }
  .ant-picker-date-panel .ant-picker-content th {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
  }

  .time-slot-wrapper {
    p {
      font-family: Inter;
      font-weight: 400;
      font-size: 1.4rem;
      color: #2d3363;
    }

    span {
      font-family: Inter;
      font-weight: 600;
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .value-text {
    font-family: Inter;
    font-weight: 400;
    font-size: 1.2rem;
    color: #2d3363;
    opacity: 0.8;
  }

  .empty-date-text {
    background: #fafafa;
    border-radius: 0.2rem;
    padding: 0.8rem;
    font-family: Inter;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 0.8rem;
  }

  .time-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0.8rem 0;

    .time-item {
      // height: 3.8rem;
      width: calc(50% - .4rem);
      border-radius: 0.2rem;
      border: 1px solid #d9d9d9;
      padding: 0.7rem 0.8rem;
      text-align: center;
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        border: 1px solid #ff9a05;
      }
      &.selected {
        background: #ff9a05;
        border-color: #ff9a05;
        color: #fff;

        span {
          color: #fff;
        }
        &:hover {
          filter: brightness(92%);
        }
      }
      .time-text {
        font-family: Inter;
        font-weight: 400;
        font-size: 1.4rem;
        color: rgba(0, 0, 0, 0.85);
      }
      .caregiver-text {
        font-family: Inter;
        font-weight: 400;
        font-size: 1.2rem;
        color: rgba(0, 0, 0, 0.85);
      }


    }
  }



  .no-slots-text {
    font-family: Inter;
    font-weight: 500;
    font-size: 1.4rem;
    color: #ff4d4f;
    margin-bottom: 1.2rem;
  }
}

@media only screen and (max-width: $small) {
  .additional-info-wrapper {
    .ant-col {
      width: 100%;
    }
  }

  .property-details {
    justify-content : space-between;
    .ant-col {
      max-width: 13.8rem;
    }
  }
}

.modal {
  .address-step {
    margin-top: 0rem;
  }
}
