.integrationWarningIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.integrationWarningIcon > svg {
  color: #FF4D4F;
}

.hiddenIntegrationIcon {
  visibility: hidden;
}

.footer {
  margin-top: 16px;
}