.empty-state-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  max-height: 38.4rem;
  .content-wrapper {
    text-align: center;
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
