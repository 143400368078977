.container {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid var(--color-gray-D9);
  background: var(--color-white);
  padding: 16px;
}
@media only screen and (max-width: 400px) {
  .container {
    max-width: 100%;
  }
}
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  width: 100%;
  display: flex;
  align-items: center;
}
.mt14 {
  margin-top: 14px;
}
.spaceBetween {
  justify-content: space-between;
}
.aiFlexStart {
  align-items: flex-start;
}
.firstColumn {
  display: block;
  width: 44px;
}
.avatarWrapper {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid var(--color-solitude-e8);
  padding: 2px;
  margin-right: 14px;
}
.avatar {
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.name {
  flex: 1;
  width: available;
  color: var(--color-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
/* I saw this beautiful title in our design */
.kebab {
  cursor: pointer;
}
.stack {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  flex: 1;
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-blue);
  background: var(--color-blue-op-06);
  width: 24px;
  height: 24px;
  border-radius: 2px;
}
.iconMessage {
  color: var(--color-purple);
}
.comment {
  cursor: pointer;
  border-radius: 2px;
  background-color: var(--color-black-1-op-09);
  color: var(--character-secondary-45);
  padding: 5px 15px;
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0 !important;
}
.dropdown {
  display: flex;
  flex-direction: column;
  padding: 18px 5px;
  border-radius: 3px;
  border: 1px solid var(--Kumba-primary-2, #22a3c3);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
}
.dropdown * {
  width: auto;
  text-align: start !important;
  transition: 0.1s;
}

.removeButton:active svg {
  color: var(--color-red);
}
.removeButton:active {
  background-color: var(--color-red-2) !important;
}
.editButton:active svg {
  color: var(--color-violet);
}
.editButton:active {
  background-color: var(--color-violet-2) !important;
}
