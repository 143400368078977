.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  width: 100%;
  display: flex;
  align-items: center;
}
.firstColumn {
  display: block;
  width: 44px;
}
.modalTitle {
  color: var(--character-title-85);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.modalContent {
  margin-top: 8px;
  color: var(--character-title-85);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 150% */
}
.modalIcon {
  color: var(--color-orange);
  font-size: 22px;
}
.modalButtons {
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 14px;
}
