.wrapper {
  position: relative;
  width: 100%;
}
.wrapper .loaderContainer {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
}
.wrapper :global(.react-datepicker__aria-live) {
  display: none !important;
}
.wrapper :global(.react-datepicker__triangle) {
  display: none;
}
.wrapper :global(.react-datepicker-popper) {
  padding-top: 0;
  z-index: 10;
  position: inherit !important;
  transform: none !important;
}
.wrapper .calendar {
  display: flex;
  justify-content: center;
  user-select: none;
}
.wrapper .section {
  width: 100%;
}
.wrapper :global(.react-datepicker__day) {
  cursor: pointer;
}
.wrapper :global(.react-datepicker__day.react-datepicker__day--disabled) {
  cursor: default;
}
.wrapper :global(.react-datepicker__day-names) {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  font-family: inter, serif;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.wrapper :global(.react-datepicker__day-name) {
  color: #b4b8cd;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.wrapper :global(.react-datepicker__week) :global(.react-datepicker__day) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.8rem;
  font-size: 1.4rem;
}
.wrapper :global(.react-datepicker__input-container) {
  display: none;
}
.wrapper :global(.react-datepicker__month-container) {
  width: 100%;
}
.wrapper :global(.react-datepicker__month) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}
.wrapper :global(.react-datepicker__week) {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.wrapper .input {
  display: none;
}
.wrapper .dp_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.8rem;
}
.wrapper .calendar {
  color: #2d3363;
  font-size: 2rem;
  font-family: inter, serif;
  font-weight: 600;
}
.wrapper .selectedDate {
  color: #f9b057;
  font-size: 1.6rem;
  font-family: inter, serif;
  font-weight: 700;
}
.wrapper .selectorWrapper {
  width: 9.4rem;
}
.wrapper .month {
  color: #2d3363;
  font-family: inter, serif;
  font-weight: 600;
  font-size: 1.8rem;
}
.wrapper .arrow {
  display: flex;
  align-content: center;
  align-items: center;
  color: #22A3C3;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
}
.wrapper .arrowRight {
  transform: rotate(180deg);
}
.wrapper .arrowDisabled {
  opacity: 0.35;
  cursor: default;
}
.wrapper .arrowDisabled svg {
  cursor: default;
}
.wrapper .monthSelectorWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
}
.wrapper .headerBottomWrapper {
  display: flex;
  justify-content: space-between;
}
.wrapper.themeBlack .calendar :global(.react-datepicker__day):hover {
  color: #ffffff !important;
  border: 1px solid #22A3C3;
  background: #22A3C3 !important;
}
.wrapper.themeBlack .calendar :global(.react-datepicker__day--selected) {
  color: #fff !important;
  border: 1px solid #22A3C3;
  background: #22A3C3 !important;
}
.wrapper.themeBlack .calendar :global(.react-datepicker__day--disabled) {
  color: #fff !important;
  background-color: #DBE2EE !important;
}
.wrapper.themeBlack .calendar :global(.react-datepicker__day--disabled):hover {
  border: none;
  color: #f5f5f5 !important;
  background-color: #DBE2EE !important;
}
.wrapper.themeBlack .calendar :global(.react-datepicker__day--today) {
  color: #000;
  border: 1px solid #22A3C3;
}
.wrapper.themeBlack .calendar :global(.react-datepicker__week) :global(.react-datepicker__day--holidays),
.wrapper.themeBlack .calendar :global(div.react-datepicker__day) + :global(div.react-datepicker__day--holidays) {
  color: #22A3C3;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
}
.wrapper.themeLight .calendar :global(.react-datepicker__day) {
  border: none;
  color: var(--color-black);
  font-weight: 500;
}
.wrapper.themeLight .calendar :global(.react-datepicker__day):not(:global(.react-datepicker__day--holidays)):hover {
  border: 1px solid var(--color-blue) !important;
}
.wrapper.themeLight .calendar :global(.react-datepicker__day.react-datepicker__day--outside-month) {
  opacity: 0.5;
  color: #d2d2d2;
}
.wrapper.themeLight .calendar :global(div.react-datepicker__day--today) {
  border: 1px solid var(--color-blue);
}
.wrapper.themeLight .calendar :global(.react-datepicker__day.react-datepicker__day--holidays) {
  color: var(--color-blue);
  font-weight: 700;
}
.wrapper.themeLight .calendar :global(div.react-datepicker__day.react-datepicker__day--selected),
.wrapper.themeLight .calendar :global(div.react-datepicker__day.react-datepicker__day--holidays):hover {
  border: 1px solid var(--color-blue);
  background: var(--color-blue);
  color: var(--color-white) !important;
}
.wrapper.themeLight .calendar :global(div.react-datepicker__day.react-datepicker__day--disabled) {
  opacity: 0.4 !important;
  cursor: default;
  background: unset !important;
}
.wrapper.themeLight .calendar :global(div.react-datepicker__day.react-datepicker__day--disabled):hover {
  border: unset !important;
}
.wrapper.themeLight .calendar :global(div.react-datepicker__day.react-datepicker__day--holidays.react-datepicker__day--disabled):hover {
  color: var(--color-blue) !important;
}
