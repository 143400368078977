.chartContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 45px -31px #2d1c1c14;
  background-color: #fff;
  border-radius: 8px;
  height: 500px;
  padding: 20px 16px 16px 16px;
}

.titleText {
  font-size: 16px;
  font-weight: 500;
}