.totalsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalsHeader b {
  font-size: 20px;
}

.lineBreak {
  background: #f5f5f5;
  height: 1px;
  width: 100%;
  margin: 20px 0px;
}

.empty {
  margin-top: 14px;
  height: 100%;
  max-height: 330px;
}

.totalAppointments {
  flex: 1;
  background-color: #fff;
  padding: 16px 32px 16px 16px;
  border-radius: 8px 0px 0px 8px;
  box-shadow: 0px 16px 45px 0px #00000014;
  height: 408px;
}

.totalsList {
  overflow: scroll;
  height: 80%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.totalsList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.titleText {
  font-size: 16px;
  font-weight: 500;
}
