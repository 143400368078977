.tile {
  flex: 1;
  height: 250px;
  min-width: 150px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-F0F0F0);
  border-radius: 2px;
}

.img {
  width: 100%;
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-FAFAFA);
}
.img svg {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
}
.container .name_container {
  display: flex;
  align-items: center;
  height: 36px;
}
.container .name_container .name {
  color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.container .areas_container {
  color: var(--character-secondary-45);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container .areas_container span:last-child {
  margin-left: 4px;
}

.controls {
  display: flex;
  border-top: 1px solid var(--color-gray-F0F0F0);
  align-items: center;
}
.controls button {
  flex: 1;
  border: unset;
  color: var(--color-black-o40);
}
.controls button:last-child:hover {
  color: var(--color-red);
}
.controls button:first-child:hover {
  color: var(--color-blue-contrasted);
}

.delimiter {
  width: 1px;
  height: 16px;
  background-color: var(--color-gray-F0F0F0);
}

@media screen and (max-width: 650px) {
  .tile {
    max-width: 100%;
  }
}
