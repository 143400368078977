.ant-message-notice-content{
  padding : 0!important;
  border-radius: 2px;
  .ant-message-custom-content{
    padding: 13px 18px;
    span:nth-child(2) {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ant-message-success{
    background: #F6FFED;
    border: 1px solid #B7EB8F;
  }
  .ant-message-info{
    background: #E6F7FF;
    border: 1px solid #91D5FF;
  }
  .ant-message-error{
    background: #FFF1F0;
    border: 1px solid #FFCCC7;
  }

  .ant-message-warning{
    background: #FFFBE6;
    border: 1px solid #FFE58F;
  }
}