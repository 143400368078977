.vet-profile-upload .ant-upload:hover .avatar-image{
  -webkit-filter: brightness(90%);
}

.vet-profile-upload .avatar-image {
  width: 12.3rem;
  height: 10rem;
  object-fit: cover;
  transition: 0.5s ease-in-out;
}
.ant-upload.ant-upload-select-picture-card{
  width: 12.3rem!important;
  height: 10rem!important;
  background: #F5F6F9!important;
  border: none!important;
}

.vet-profile-upload {
  height: 100%;
  padding-bottom: 20px;
}

.vet-profile-upload .ant-form-item, .ant-form-item-row, .ant-form-item-control-input, .ant-form-item-control-input-content, .avatar-uploader {
  height: 100%;
}
