.popover {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.iconExclamationCircleFilled {
  color: #FAAD14;
}

.iconCheckCircleFilled {
  color: #52C41A;
}
