@import '~antd/dist/antd.css';

.ant-picker-cell-disabled::before{
  background: transparent!important;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  border: none!important;
}

calendar-header-arrows{
  color: #22A3C3!important;
}
calendar-.header-arrows:hover{
  color: #22A3ff!important;
}
.calendar-header-title{
  text-align:center;
  font-weight: 500;
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 5px;
  margin-bottom: 5px;
}


.cell-today.cell-timeslots{
  color : #22A3C3;
}
.antd-cell{
  padding: 3.4px 5.5px;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  border-radius: .2rem;
}
.ant-picker-cell-selected .antd-cell{
  background: #22A3C3;
  color: #fff;
}
.ant-picker-cell-selected.cell-today{
  color: #fff!important;
}
.cell-timeslots{
  color: #22A3C3;
  font-weight: 700;
}
.cell-timeslots-past{
  color: #22A3C3;
  opacity: 0.6;
  font-weight: 700;
}
.cell-today{
  border: 1px solid #22A3C3;
  color: #141414;
}

.cell-without-timeslot{
  color: #141414;
}
.ant-picker-cell-disabled .antd-cell{
  color: #D9D9D9!important;
}
.ant-picker-suffix {
display: flex;
flex: none;
align-self: center;
margin-left: 4px;
line-height: 1;
pointer-events: none;
color: rgba(34, 163, 195, 1);
}
#components-layout-demo-responsive .logo {
height: 32px;
margin: 16px;
color: white;
background: rgba(255, 255, 255, 0.2);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
color: #fff !important;
background: rgba(34, 163, 195, 1) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
border: 1px solid rgba(34, 163, 195, 1) !important;
}

.ant-picker-date-panel .ant-picker-content th {
width: 36px;
font-weight: 600;
font-size: 16px;
}
.site-layout-sub-header-background {
background: #fff;
}
.calender-heading {
font-weight: 600;
font-size: 16px;
position: relative;
top: 53px;
left: 10px;
margin-top: -30px;
}

.site-layout-background {
background: #fff;
}

.logo-title {
color: white;
text-align: center;
font-family: 'Arial';
font-weight: 600;
font-size: 24px;
margin-top: 20px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
background-color: rgba(0, 0, 0, 0.16);
}

.ant-layout-sider {
position: relative;
min-width: 0;
background: rgba(50, 59, 75, 1);
transition: all 0.2s;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
background: rgba(50, 59, 75, 1);
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
color: white;
font-size: 16px;
font-weight: 500;
margin-top: 20px;
}

.site-calendar-demo-card {
border: 1px solid #f0f0f0;
border-radius: 10px;
width: 100%;
height: auto;
margin-left: auto;
margin-right: auto;
position: relative;
z-index: 1;
color: rgba(0, 0, 0, 0.85);
background-color: #fff;
padding: 20px;
margin-bottom: 0px;
box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);
padding-top: 0;
padding-bottom: 0;
}

.homepage {
width: 100%;
height: auto;
margin-left: auto;
margin-right: auto;
position: relative;
z-index: 1;
color: var(--main-fore-color);
background-color: var(--main-screen-color);
padding-bottom: 40px;
}

.add-timeslot-btn {
text-align: right;
margin-right: 20px;
margin-top: 20px;
}

.timeslot-title-date {
display: flex;
margin-left: 20px;
margin-top: 20px;
}

.time-slot-heading {
font-size: 16px;
font-weight: 600;
}

.time-slot-date {
font-size: 14px;
font-weight: 400;
color: rgba(34, 163, 195, 1);
margin-top: 2px;
margin-left: 20px;
}

.appointment-heading {
font-size: 16px;
font-weight: 600;
}

.appointment-counts {
font-size: 14px;
font-weight: 400;
color: rgb(167, 167, 167);
margin-top: 2px;
margin-left: 20px;
}


.name-and-location {
display: flex;
}

.cc-and-content {
display: flex;
margin-top: -14px;
}

.person-name {
font-size: 16px;
font-weight: 600;
}

.location {
font-size: 14px;
font-weight: 400;
margin-top: 2px;
margin-left: 10px;
}

.cc {
color: blue;
}

.cc-content {
font-size: 12px;
margin-top: 2px;
margin-left: 5px;
}

.dog-image {
width: 30px;
height: 30px;
border-radius: 50px;
}

.user-image {
width: 30px;
height: 30px;
border-radius: 50px;
}

.cat-image {
width: 30px;
height: 30px;
border-radius: 50px;
}

.animal-images {
text-align: right;
margin-right: 30px;
}

.user-info {
text-align: right;
margin-right: 40px;
}

.menu-icon {
font-size: 16px;
margin-left: 10px;
display: none;
}

.ant-dropdown-link {
color: black;
font-size: 16px;
font-weight: 600;
margin-left: 10px;
}

.ant-layout-sider-zero-width-trigger {
position: absolute;
top: 12px;
right: -36px;
z-index: 1;
width: 36px;
height: 42px;
color: #fff;
font-size: 18px;
line-height: 42px;
text-align: center;
background: #001529;
border-radius: 0 2px 2px 0;
cursor: pointer;
transition: background 0.3s ease;
}

td.ant-table-cell a {
color: black;
}

.ant-modal-header {
padding: 16px 24px;
color: rgba(0, 0, 0, 0.85);
background: #fff;
border-bottom: none;
border-radius: 2px 2px 0 0;
}

.ant-modal-footer {
display: none;
}

.recommendations {
margin-top: -20px;
margin-left: 24px;
}

.ant-modal-body {
padding: 0px 0px 0px 0px;
font-size: 14px;
line-height: 1.5715;
word-wrap: break-word;
}

.modal-form {
margin-top: 20px;
padding: 0px 24px 24px 24px;
}

.date-input {
width: 100%;
}

.time-input {
width: 100%;
}

.appointment-details {
box-sizing: border-box;
margin-top: 6px;
}

.circle {
width: 13px;
height: 13px;
border: 3px solid rgb(103, 198, 230);
border-radius: 50%;
margin-left: -1%;
position: absolute;
background: #ffffff;
z-index: 9;
}

.vertical-line {
width: 2px;
height: 60px;
background: rgba(0, 0, 0, 0.06);
margin-left: 5px;
}

.ant-radio-group.ant-radio-group-outline.ant-radio-group-small.ant-picker-calendar-mode-switch {
display: none;
}
.zipcode-input {
width: 100%;
}
.okBtn {
margin-left: 10px;
}
.modal-buttons {
margin-top: 20px;
text-align: right;
}
.menu-modal {
padding-bottom: 20px;
}
.ant-modal.sidebar-modal {
margin-top: -86px;
}
.tooltip-text {
margin-left: 10px;
}
.tooltip-div {
margin-bottom: 10px;
}
.more-btn {
margin-left: 10px;
background-color: rgba(34, 163, 195, 1);
}
.more-btn-appointment {
background-color: rgba(34, 163, 195, 1);
}
.calender-style {
border-radius: 10px 10px 10px 10px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .name-and-location {
    display: inline;
  }
  .location {
    font-size: 14px;
    font-weight: 400;
    margin-top: -15px;
    margin-left: 0px;
  }

  .calender-heading {
    font-weight: 600;
    font-size: 22px;
    position: relative;
    top: 31px;
    left: 10px;
  }


  .ant-layout-sider {
    position: relative;
    min-width: 0;
    background: rgba(50, 59, 75, 1);
    transition: all 0.2s;
    z-index: 1;
  }

  .menu-icon {
    margin-left: -100px;
    margin-top: 20px;
    display: block;
  }

  .animal-images {
    text-align: left;
    margin-left: 30px;
  }

  aside#sidebar {
    display: none;
  }
  .ant-table-tbody > tr > td {
    padding: 14px;
  }
  .timeslot-title-date {
    display: flex;
    margin-left: 6px;
    margin-top: 20px;
  }
}
