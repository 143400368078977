.warningIcon {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.confirmationText {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.deleteInfo {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;
}

.confirmDeleteInputText {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.deleteButton {
  width: 100%;
}

.deleteButtonEnabled {
  background: #ff4d4f !important;
  color: var(--color-white) !important;
  border: none !important;
}

.input {
  margin-bottom: 12px;
}