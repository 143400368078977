.h5 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.opacity65 {
  opacity: 0.65;
}

.pRegular,
.s14h22w4 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.s14h22w5 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.s14h16w4 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.caption {
  color: var(--color-gray-B8);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.p1 {
}
.p2 {
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.p3 {
}

.label {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  height: 22px;
}

/* naming for new text style combination in design: s12h16w4 = s - fontSize, h - lineHeight, w - fontWeight */
.s12h16w4 {
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.s12h18w4 {
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.s12h18w5 {
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.s12h24w4 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.s12h20w4 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.s16h25w6 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.s16h24w5 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.s13h18w4 {
  font-family: Inter, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.s12h16w5 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.s11h18w4 {
  font-family: Inter, serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.italic {
  font-style: italic;
}

.s22h29w7i {
  font-family: Inter, serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
}

.s16h24w5i {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.s14h24w5 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.s18h24w6 {
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.s18h24w5 {
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.s16h29w6 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 29px;
}
.s20h24w6 {
  font-family: Inter, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.label {
  display: block;
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  height: 22px;/* 157.143% */
}

.s16w5l24 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.s16w6l18 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}
.s14w6l18 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}
.s14w4l24 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.s12w5l18fI {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.s14w4l18fI {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.s14w4l20fI {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.s18w7l24 {
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.s12w5l14 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}
.s14w5l16 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}
.s12w2l20 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.s12w5l18 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.s12w4l20 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.s12w4l22 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.s12w7l22 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}
.s12w6l22 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.s12w5l20 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.s12w6l20 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.s12w7l20 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
.s14w4l22 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.s14w5l22 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.s12w400l24 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.s12w4l22 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.s12w6l20 {
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.s14w5l22 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.s14w5h20i {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.s24w5h28 {
  font-family: Inter, serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.s16w4h24 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.s14w4l18 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.s16w5l20 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.s16w4l20 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.s16w4l24 {
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.s12w4l20_error {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-red);
}

.s14h20w5 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.s14h20w4 {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.s16h22w5 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.s14h18w4 {
  color: var(--character-title-85);
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.s12h18w6 {
  font-family: Inter, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
