.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12rem 0;
}
.wrapper {
  padding-top: 18rem;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 3.2rem;
  color: #000;
  margin-bottom: 2rem;
  cursor: initial;
}
.subTitle {
  font-family: inter, assistant;
  font-size: 1.8rem;
  font-weight: 500;
  color: #8a94a6;
  margin-bottom: 3.2rem;
}
.content {

}
.bottom {
  margin-top: 2rem;
}
.question {
  font-family: inter, assistant;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #b0b7c3;
  margin-right: 0.5rem;
}
.link {
  font-family: inter, assistant;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #26acb9;
}

.back {
  display: flex;
  gap: 10px;
  align-items: baseline;
  text-decoration: none;
}
.back:hover, .back:active, .back:visited {
  color: var(--character-title-85);
}
.back svg {
  fill: var(--character-title-85);
}
