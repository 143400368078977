.cGrayD9 {
  color: var(--color-gray-D9);
}

.cGrayB8 {
  color: var(--color-gray-B8);
}

.cBlackO40 {
  color: var(--color-black-o40);
}

.cBlackO45 {
  color: var(--character-secondary-45);
}
.cCharterPrimary85 {
  color: var(--character-title-85);
}

.c8A94A6 {
  color: var(--color-8A94A6);
}

.cBlue {
  color: #22a3c3;
}

.cRed {
  color: #e44e61;
}

.cYellow {
  color: #ff9a05;
}

.cC9d4E3 {
  color: #c9d4e3 !important;
}

.c323B4B {
  color: var(--color-323B4B);
}

.title85 {
  color: var(--character-title-85);
}
.cGrayD9 {
  color: var(--color-gray-D9);
}
.cGrayB8 {
  color: var(--color-gray-B8);
}
.cGray8c {
  color: #8c8c8c;
}
.cBlue {
  color: #22a3c3;
}

.cRed {
  color: #e44e61;
}

.cYellow {
  color: #ff9a05;
}

.cWhite {
  color: #ffffff;
}
.cB0B7C3 {
  color: var(--color-B0B7C3);
}

.secondary45 {
  color: var(--character-secondary-45);
}

.eDF8FABackground {
  background: #edf8fa !important;
}

.cBlueBackground {
  background: #22a3c3 !important;
}

.cBlueImportant {
  color: #22a3c3 !important;
}

.cWhiteImportant {
  color: #ffffff !important;
}
