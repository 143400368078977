.container {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-white);
}

.layout {
  display: flex;
}
.layout > *:first-child {
  min-width: 240px;
}
.layout > *:last-child {
  flex: 1;
}
.layout .filters {
  background: var(--color-white);
}
.layout .filterTitle {
  display: flex;
  flex-direction: column;
}
.layout .title {
  font-size: 20px;
  padding-bottom: 12px;
}
.layout .clearFilters {
  margin-bottom: 20px;
}
.layout .skeleton {
  padding: 20px;
}
.layout .rowItem {
  height: unset !important;
  border-width: 0 !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  overflow-wrap: break-word;
  white-space: normal;
}
.layout .rowItemContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.layout .rowItemContent span:first-child {
  margin-top: 7px;
}
.layout .rowItemContent span {
  text-align: left;
  max-width: 150px;
}
@media screen and (max-width: 1010px) {
  .layout {
    flex-direction: column;
  }
  .layout .filters {
    margin-bottom: 20px;
  }
  .layout :global(.ant-spin-nested-loading) {
    min-height: unset;
  }
  .layout .title, .layout .clearFilters {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .layout .rowItemContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .layout .rowItemContent span {
    max-width: unset;
  }
  .layout .filterTitle {
    padding: 0;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  .layout :global(.rbc-time-header-gutter), .layout :global(.rbc-time-column) {
    z-index: 9 !important;
  }
  .layout :global(.rbc-toolbar) {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    flex-direction: column;
  }
  .layout :global(.rbc-toolbar) > span:nth-child(1) {
    order: 2;
  }
  .layout :global(.rbc-toolbar) > span:nth-child(2) {
    order: 1;
  }
  .layout :global(.rbc-toolbar) > span:nth-child(3) {
    order: 3;
  }
}
