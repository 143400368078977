.container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
}

.select {
  flex: 1;
  width: 100%;
}

.selectType {
  width: 125px;
}
