.container {
  flex: 1;
  height: 200px;
  min-width: 150px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.node :global(.ant-skeleton-button-lg) {
  height: 140px !important;
}

.node {
  width: 100%;
  height: 140px;
}
