.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.flex {
  display: flex;
  gap: 8px;
  align-items: center;
}

.flex span {
  text-overflow: ellipsis;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
