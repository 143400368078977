.ant-form-item-label {
  padding: 0rem !important;
}
svg {
  cursor: pointer;
}
.address-info-icon{
  color : #BFBFBF!important;
}
.address-info-icon:hover path{
  fill: black!important;
}
.address-info-icon {
  font-size: 1.2rem !important;
}
