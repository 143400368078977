.ant-form-item-label {
  padding: 0rem !important;
}

.ant-input {
  height: 32px !important;
}

svg {
  cursor: pointer;
}
.ant-tooltip-arrow-content{
  top: -1rem!important;
}
