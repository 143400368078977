.vet-profile-form .ant-col-lg-12,
.ant-col-lg-8 {
  height: 67px !important;
}
.language-row .ant-col-lg-12,
.ant-col-lg-8 {
  height: auto !important;
}

.vet-profile-button-wrapper {
  margin-top: 1.6rem;
}
