.modal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /*overflow: hidden;*/
}
.themeLight {
  background-color: #fff;
}
.header {
  background-color: #fff;
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #000000d9;
}
.header {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.btnLeft {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.btnRight {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
