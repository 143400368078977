.modal {
}

/*region Modal Header*/
.modalHeader {
  display: flex;
  flex-direction: column;
}
.modalHeader :global(.appointments-header-wrapper) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 40px;
  gap: 10px;
}
.modalHeader :global(.appointments-stepper-wrapper) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalHeader :global(.search-input) {
  max-width: 17.3rem;
}
.modalHeader :global(.appointment-btn) {
}
.modalHeader :global(.vet-calendar-steps) {
  border-radius: 0.4rem;
  background: #fff;
  box-shadow: 0 4px 45px -31px rgba(45, 28, 28, 0.08);
}
.modalHeader :global(.ant-form-item-with-help) {
  margin-bottom: 2.4rem !important;
}
.modalHeader :global(.ant-steps-item) {
  flex: initial !important;
}
.modalHeader :global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon) {
  background: rgba(255, 154, 5, 1);
  border: none;
}
.modalHeader :global(.ant-steps-item-finish .ant-steps-item-icon) {
  background-color: #fff;
  border-color: rgba(56, 158, 13, 1);
}
.modalHeader :global(span.anticon.anticon-check.ant-steps-finish-icon) {
  color: rgba(56, 158, 13, 1);
}
.modalHeader :global(.text-right) {
  text-align: right;
}
.modalHeader :global(.ant-btn-primary) {
  background-color: #ff9a05 !important;
  border: 1px solid #ff9a05 !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
}
.modalHeader :global(.ant-btn-primary[disabled]),
.modalHeader :global(.ant-btn[disabled]) {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.modalHeader :global(.ant-steps-item-title) {
  font-family: Inter;
  font-weight: 400;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.45);
}
.modalHeader :global(.ant-steps-item-active .ant-steps-item-title) {
  color: #ff9a05 !important;
}
.modalHeader :global(.footer-wrapper) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalHeader :global(.pet-name-input) {
  width: 15.2rem;
}
.modalHeader :global(.stepper-footer) {
  display: inline-flex;
  align-items: center;
}
.modalHeader :global(.stepper-footer .error-message) {
  color: #ff4d4f;
  font-family: Inter;
  font-weight: 400;
  font-size: 1.4rem;
  margin-right: 1rem;
}
.modalHeader :global(.stepper-footer .back-btn) {
  margin-right: 1.2rem;
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 1250px) {
  .modalHeader :global(.vet-calendar-steps.booking-page) {
    width: 100%;
    padding: 2.4rem 2rem;
  }
}
@media only screen and (max-width: 1080px) {
  .ant-steps-item-tail {
    display: none !important;
  }
  .modalHeader :global(.ant-steps-item) {
    flex: 1 0 auto !important;
  }
  .modalHeader :global(.vet-calendar-steps) {
    width: 100%;
  }
  .modalHeader :global(.ant-picker-calendar-header .ant-picker-calendar-year-select) {
    width: 7.4rem;
  }
  .modalHeader :global(.ant-picker-calendar-header .ant-picker-calendar-month-select) {
    width: 7.4rem;
  }
  .modalHeader :global(.ant-picker-calendar-mini .ant-picker-calendar-header) {
    float: right;
  }
  .modalHeader :global(.ant-steps-vertical) {
    flex-direction: row !important;
  }
  .modalHeader :global(.ant-steps-item),
  .modalHeader :global(.ant-steps-item-container) {
    display: inline-flex;
  }
  .modalHeader :global(.ant-steps-item-wait .ant-steps-item-content) {
    display: none !important;
  }
  .modalHeader :global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon) {
    margin-right: 0.8rem;
  }
  .modalHeader :global(.pet-name-input) {
    width: 13.9rem;
    margin-left: 0.8rem;
  }
  .modalHeader :global(.icon-wrapper) {
    padding-left: 1.5rem !important;
  }
  .modalHeader :global(.btn-create) {
    margin-bottom: 3.6rem !important;
  }
  .modalHeader :global(.patient-details-col) {
    width: 100%;
  }
  .ant-steps-item-finish .ant-steps-item-tail,
  .ant-steps-item-finish .ant-steps-item-title {
    display: none !important;
  }
}
/*endregion*/

/*region Modal Footer*/
.modalFooter :global(.appointments-stepper-wrapper) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.6rem;
}
.modalFooter :global(button) {
  max-width: 8.1rem;
  height: 4rem;
  border-radius: 0.2rem;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}
.modalFooter :global(.cancel-btn) {
  margin-right: 1.2rem;
  border: 1px solid #d9d9d9 !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85);
}
.modalFooter :global(.next-btn.disabled) {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
  cursor: not-allowed !important;
}
/*endregion*/

/*region Modal Content*/
.modalContent {
  display: flex;
  flex-direction: column;
}
.modalContent :global(.appointments-content-wrapper) {
}
.modalContent :global(.client-card-wrapper) {
  padding: 15px;
  width: 100%;
  min-height: 10rem;
  border: 1px solid #c9d4e3;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.modalContent :global(.client-card-wrapper) > div:last-child {
  flex: 5;
}
.modalContent :global(.client-card-wrapper) > div:first-child {
  flex: 1;
}
.modalContent :global(.client-card-wrapper.active) {
  box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.2);
  border-color: #22a3c3;
}
.modalContent :global(.client-card-wrapper .client-details-wrapper) h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #112950;
  opacity: 0.8;
  margin-bottom: 0.4rem;
  margin-top: 0;
}
.modalContent :global(.client-card-wrapper .client-details-wrapper) h5 {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.45);
}
.modalContent :global(.client-card-wrapper .client-details-wrapper) h5:hover {
  text-decoration: underline;
}
.modalContent :global(.client-card-wrapper .client-details-wrapper .address-wrapper) {
  display: flex;
}
.modalContent :global(.client-card-wrapper .client-details-wrapper .address-wrapper) h4 {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #585ce5;
  margin-top: 0;
  margin-right: 1rem;
  margin-bottom: 0;
}
.modalContent :global(.client-card-wrapper .client-details-wrapper .address-wrapper) h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.modalContent :global(.client-card-wrapper .client-details-wrapper) svg {
  fill: #22a3c3;
}

.modalContent :global(.clients-empty-state) {
  text-align: center;
  margin-top: 2.4rem;
}
.modalContent :global(.clients-empty-state) h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.8rem;
}
.modalContent :global(.clients-empty-state) p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #36517e;
  margin-bottom: 1.6rem;
}
.modalContent :global(.clients-empty-state) img {
  margin-bottom: 1.6rem;
}
.modalContent :global(.clients-empty-state .copy-url-input) {
  width: 100%;
  max-width: 38.6rem;
  margin-bottom: 1.6rem;
  display: block;
  margin: auto;
}
.modalContent :global(.clients-empty-state .copy-url-input input) {
  border-right: 0;
}

.modalContent :global(.clients-empty-state .copy-url-input .ant-input-group-addon:last-child) {
  background: #f5f5f5;
}
.modalContent :global(.clients-empty-state .copy-icons-wrapper) {
  display: flex;
  align-items: center;
}
.modalContent :global(.clients-empty-state .copy-icons-wrapper .copy-svg) svg {
  fill: #585ce5;
}
.modalContent :global(.clients-empty-state .copy-icons-wrapper .share-svg) svg {
  fill: #22a3c3;
}
.modalContent :global(.clients-empty-state .line-between-icons) {
  width: 0.1rem;
  height: 1.6rem;
  margin: 0 0.55rem 0 0.85rem;
  background: #22a3c3;
  opacity: 0.2;
  display: inline-flex;
}

/*endregion*/
