.radio-wrapper{
  position: relative;
}
.info-icon{
  position: absolute;
  top: -16px;
  left: 42px;
}
.ant-tooltip-inner{
  white-space: pre-line;
}