.selectInput :global(.ant-select) {
  height: 0 !important;
}
.selectInput :global(.ant-select-selector) {
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: unset !important;
  visibility: hidden;
}
.selectInput :global(.ant-select-selection-overflow *) {
  display: none;
}

.selectPopup {
  padding: 0;
}

.row {
  margin-top: -14px;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}
