@import '../../../common_repo/styles/mixins';

#scrollableDiv {
  max-height: 60vh;
}
@for $i from 0 through 2 {
  @media screen and (min-width: 500 + $i * 300px) {
    .appointments-content-wrapper {
      min-width: 400 + $i * 150px;
    }
  }
}
.app-footer-inform {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.new-appointment-wrapper.vet-calendar-steps {
  width: 100%;
}
.new-appointment-wrapper {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  & > form {
    flex: 1
  }
  .appointments-header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .appointments-stepper-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .appointments-footer-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;
    button{
      max-width: 8.1rem;
      height: 4rem;
      border-radius: .2rem;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    }
    .cancel-btn{
      background: white;
      border: 1px solid #FF9A05;
      margin-right: 1.6rem;
    }
    .next-btn.disabled{
      background: #F5F5F5;
      color: rgba(0, 0, 0, 0.25);
      border: 1px solid #D9D9D9;
      cursor : not-allowed!important;
    }
  }
  .search-input {
    max-width: 17.3rem;
    // height: 3.2rem;
  }
  .clients-empty-state {
    text-align: center;
    margin-top: 2.4rem;
    @include sm{
      margin-bottom: 2rem;
    }

    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: .8rem;
    }

    p {
      font-family: inter, assistant;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #36517E;
      margin-bottom: 1.6rem;
    }

    img {
      margin-bottom: 1.6rem;
    }

    .copy-url-input {
      width: 100%;
      max-width: 38.6rem;
      margin-bottom: 1.6rem;
      display: block;
      margin: auto;
      input{
        border-right: 0;
      }
      .ant-input-group-addon:last-child{
        background: #F5F5F5;
      }
    }
    .copy-icons-wrapper{
      display: flex;
      align-items: center;
      .copy-svg svg {
        fill : #585CE5;
      }
      .share-svg svg{
        fill : #22A3C3;
      }
    }
    .line-between-icons {
      width: .1rem;
      height: 1.6rem;
      margin: 0 0.55rem 0 .85rem;
      background: #22A3C3;
      opacity: 0.2;
      display: inline-flex;
    }
  }
  .appointments-content-wrapper{
    flex: 1;
    overflow-y: auto;
    @include sm {
      height: auto;
    }
    &.d-flex{
      display: flex;
      align-items: center;
    }
    .client-card-wrapper {
      width: 100%;
      height: 10rem;
      padding: 1.6rem 2.9rem;
      border: 1px solid #C9D4E3;
      border-radius: .3rem;
      margin: .2rem auto 1.6rem;
      cursor: pointer;
      display: flex;
      @include sm {
        padding: 1.6rem 2rem!important;
        height: auto;
      }
      &.active{
        box-shadow: 0 0 0 .2rem rgba(24, 144, 255, 0.2);
        border-color : #22A3C3;
      }
      .client-details-wrapper{
        h3{
          font-family: inter, assistant;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #112950;
          opacity: 0.8;
          margin-bottom: .4rem;
          margin-top: 0;
        }
        h5{
          font-family: Inter;
          font-style: italic;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          margin-top: 0;
          margin-bottom: 0;
          color: rgba(0, 0, 0, 0.45);
          &:hover{
            text-decoration: underline;
          }
        }
        .address-wrapper{
          display: flex;
          h4{
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #585CE5;
            margin-top: 0;
            margin-right: 1rem;
            margin-bottom: 0;
          }
        }
        svg{
          fill : #22A3C3;
        }
      }
    }
  }
}
@include sm{
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content{
    min-height: auto!important;
  }
}
