.container {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
}
.row {
  display: flex;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
}
.columns {
  gap: 10px;
}
.columns > * {
  flex: 1;
}
.bottomCaption {
  text-align: right;
}
.mb12 {
  margin-bottom: 12px;
}
.flexColumns > * {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .flexColumns {
    gap: 10px;
  }
}
@media screen and (max-width: 599px) {
  .flexColumns {
    flex-direction: column;
  }
}
