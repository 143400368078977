.controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selectOrder {
  min-width: 200px;
}

@media screen and (max-width: 650px) {
  .controls {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .controls button {
    min-width: 100%;
  }
  .controls :global(.ant-select) {
    width: 100%;
  }
}
