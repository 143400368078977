.container {
  display: flex;
  padding-bottom: 20px;
  gap: 10px;
  max-width: 1600px;
  min-width: 400px;
}

.filters {
  width: 220px;
  display: flex;
  flex-direction: column;
}
.map {
  width: 100%;
}

@media screen and (min-width: 701px) {
  .container {
    min-width: 600px;
    min-height: 500px;
  }
}
@media screen and (min-width: 1100px) {
  .container {
    min-width: 900px;
    min-height: 500px;
  }
}
@media screen and (max-width: 700px) {
  .container {
    flex-direction: column;
  }
  .map {
    height: 400px;
  }
}
.providers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}
.vetCheckbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 12px;
}

.colorBox {
  width: 10px;
  height: 10px;
  border-radius: 1px;
}

.user {
  display: flex;
  align-items: center;
  gap: 5px;
}
