.btnCreate {
  display: block;
  width: auto;
}
.modal :global(.ant-modal-header) {
  border-bottom: unset !important;
}
.container {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 16px 24px;
}
