.container {
  padding-top: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 505px;
  gap: 10px;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .container {
    min-width: 100vw;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    min-width: 80vw;
  }
}
@media screen and (min-width: 1001px) {
  .container {
    min-width: 70vw;
  }
}
.noPadding {
  padding: 26px 0px;
}

.filtersColumn {
  display: flex;
  flex-direction: column;
  flex: 2;
}
.timeslotsWrapper {
  flex: 4;
}
.timeslots {
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 50vh;
  height: calc(100% - 50px);
  overflow-y: auto;
}
.loaderTimeslots {
  max-height: inherit;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.map {
  flex: 2;
  min-width: 300px;
}
.filtersColumn :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
}
.checkBox {
  margin-top: 12px !important;
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
}
.footer > * {
  flex: 1;
  min-width: 40%;
  max-width: 500px;
}

.monthSelectorWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
}
.arrow {
  display: flex;
  align-content: center;
  align-items: center;
  color: #22a3c3;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
}
.arrowRight {
  transform: rotate(180deg);
}
.arrowDisabled {
  opacity: 0.35;
  cursor: initial;
}
.selectorWrapper {
  width: 180px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sort {
  width: 60px;
}
.month {
  color: #2d3363;
  font-family: inter, serif;
  font-weight: 600;
  font-size: 1.8rem;
}

@media screen and (max-width: 1000px) {
  .timeslotsWrapper {
    max-width: 100%;
  }
  .container {
    flex-direction: column;
    justify-content: unset;
  }
  .map {
    height: 300px;
    flex: unset;
  }
  .filters {
    cursor: pointer;
    maargin-bottom: 10px;
  }
}
.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.arrowFilter {
  display: flex;
  align-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
  transform: rotate(-90deg);
}
.arrowFilter > * {
  fill: #80859b !important;
}
.arrowDown {
  transform: rotate(90deg);
}

.filtersContent {
  display: flex;
  flex-direction: column;
}
.filtersContentHide {
  display: none;
}
.error {
  color: #ff4d4f;
}

.petIcon {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-F5);
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  height: 100%;
}
.petIcon > span {
  max-width: 280px;
  text-align: center;
}
.labelNoTimeslots {
  font-weight: 500;
}
