//@import 'common/styles/global-styles';

.ant-tag-has-color {
  color: #212b35 !important;

  .anticon-close {
    color: #212b35 !important;
  }
}

.settings-wrapper {
  margin: auto;
  max-width: 69.2rem;

  .settings-go-back-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      fill: rgba(0, 0, 0, 0.45);
    }

    .settings-go-back-title {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      color: rgba(0, 0, 0, 0.45);
      margin-left: .9rem;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  .d-flex {
    display: flex;
    width: 7.5rem;
  }

  .align-items-center {
    align-items: center;
  }

  .range-input-content {
    width: 100%;
    max-width: 42.2rem;
  }

  .preferences-input {
    height: 40px !important;
    border-top-right: 0;
    border-bottom-right-radius: 0;
  }

  .preference-btn {
    padding: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .preferences-layout {
    width: 100%;
    background: #fff;
    max-width: 69.2rem;
    border-radius: .8rem;
    margin-bottom: 1.6rem;
    padding: 3.2rem;
    border: 1px solid #D6DBE6;
  }

  .notification-description{
    font-size: 1.5rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    color: #647482;
  }

  .settings-text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .settings-title {
    font-size: 2.8rem;
    color: #212b35;
    margin-bottom: 2.7rem;
    font-weight: 400;
  }

  .settings-sub_title {
    font-size: 1.8rem;
    color: #212b35;
    margin-bottom: 1.5rem;
  }

  .settings-sub_description {
    font-size: 1.5rem;
    color: #647482;
    margin-bottom: 2.4rem;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2.4rem;
    border: 1px solid #d6dbe6;
    border-bottom: none;

    &.hoverable:hover,
    &.hoverable:focus,
    &.hoverable:active {
      background-color: #f6f6f6;
      border-color: #d6dbe6;
    }

    &.flex-wrap {
      flex-wrap: wrap;
    }

    &.d-block {
      display: block;
    }

    &.first-child {
      border-top-left-radius: 0.8rem !important;
      border-top-right-radius: 0.8rem !important;
    }

    &:last-child {
      border-bottom: 1px solid #d6dbe6;
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }

    .ant-switch-checked {
      background-color: #21a3c3 !important;
    }

    .notification-title {
      font-size: 1.6rem;
      color: #212b35;
      margin-bottom: .7rem;
    }

    .notification-description {
      font-size: 1.4rem;
      color: #647482;
    }

    .range-slider {
      width: 100% !important;
      margin-top: 2rem;
    }

    .ant-slider-handle {
      border-color: #697b8c !important;
    }

    .ant-slider-track {
      background-color: #697b8c !important;
    }

    .ant-tooltip-inner {
      background-color: #697b8c !important;
    }

    .ant-slider-handle {
      border-color: #21a3c3 !important;
    }

    .ant-slider-track {
      background-color: #21a3c3 !important;
    }
  }
}

.notification-text-area {
  width: 100%;
  height: 30rem;
  resize: none;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    border-right-width: 1px !important;
    outline: 0;
  }
}

.notification-text-wrapper {
  width: 500px;
  background-color: #fff;
  border-radius: 0.8rem;
  padding: 4rem;

  textarea {
    height: 10rem !important;
    resize: none;
    margin-bottom: 2rem;
  }

  .keys-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .keys-wrapper::after {
    content: '';
    flex: auto;
  }

  .key-btn {
    padding: 0.5rem;
    width: auto;
    margin: 0.3rem;
  }

  .btn-default{
    width:8.7rem;
    height:4rem;
  }

  .btn-primary{
    width:8.7rem;
    height:4rem;
  }

  .modal-footer {
    text-align: center;

    button {
      margin: 0 1rem;
      padding: 0.5rem;
    }
  }
}

.modal-titles-wrapper {
  margin-bottom: 1.5rem;

  .notification-title {
    font-size: 1.6rem;
    color: #212b35;
    margin-bottom: 1rem;
  }

  .notification-description {
    font-size: 1.4rem;
    color: #647482;
  }
}

.optional-parameters-title {
  font-size: 1.4rem;
  color: #647482;
}

.supported-services-layout {
  //padding: 2.4rem 2rem 2.8rem !important;
  position: relative;
  .settings-text {
    padding-left: 2.4rem;
  }
  .empty-appointments {
    height: 21.6rem;
  }
  .supported-services-btn{
    position: absolute;
    top: 3.2rem;
    right: 2.749rem;
    font-weight: 400;
    font-size: 1.4rem;
  }
}

.add-service-btn svg {
  margin-right: .9rem;
}

.supported-services-list {
  max-height: 40.7rem;
  overflow-y: auto;
  padding-right: 1.35rem;

  .supported-services-list__item {
    padding: .971rem 1.7rem 0 2.4rem;
    margin-top: .7rem;

    &.disabled-service-item{
      .supported-services-list__details h1,
      .supported-services-list__details svg,
      h1,{
        opacity: 0.4!important;
      }
    }
    &:first-child {
      margin-top: 2.4rem;
    }

    &:hover {
      background: #f4f5f7;
      border-radius: .5rem;
      cursor: pointer;
      transition: .3s easy;

      .supported-services-list__wrapper {
        border-bottom: 1px solid transparent;
      }

      .supported-services-list__edit {
        display: block;
      }
    }

    .supported-services-list__edit {
      display: none;
    }

    .services-details__line {
      width: .1rem;
      height: 1.2rem;
      opacity: 0.08;
      background: #2D3363;
      margin: 0.5rem 1.2rem 0.5rem 0.5rem;
    }

    .supported-services-list-item__title {
      margin-bottom: .529rem;
      font-family: Inter;
      font-style: normal;
      font-size: 1.6rem;
      font-weight: 400;
      color: #212B35;
    }

    .supported-services-list__wrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(45, 51, 99, .05);
    }

    .supported-services-list__details {
      display: flex;
      margin-bottom: .971rem;

      .services-details__title {
        opacity: 0.6;
        color: #2D3363;
        font-size: 12px;
        font-weight: 400;
        margin-left: .6rem;
        font-style: normal;
        line-height: 1.4rem;
        font-family: Inter;
        margin-bottom: 0;
        white-space: nowrap;
      }

      .services-clock-icon path {
        fill: var(--color-blue);
      }

      .services-eye-icon__active path {
        fill: var(--color-blue);
      }

      svg {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0;
      }
    }
  }
}

.add-service-btn {
  width: 14.7rem !important;
  height: 3.2rem !important;
  border-radius: .2rem !important;
}

.range-input-modal {
  width: 44.4rem;
  height: 27.8rem;
  border-radius: .5rem;
  padding: 1.9rem 1.6rem 2.4rem 2.4rem;

  .notification-title {
    font-size: 1.5rem !important;
  }

  .notification-description {
    font-size: 1rem !important;
  }

  .range-slider {
    margin-top: 6rem;
  }

  .slider-details-wrapper {
    display: flex;
    justify-content: space-between;
    font-family: Inter;
    font-weight: 400;
    font-size: 1.4rem;
    color: #212B35;
    margin-bottom: 3.3rem;
  }

  .address-autocomplete-input {
    margin-top: 3rem !important;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0 0.4rem;
      padding: 0;
    }
  }
}

.new-service-modal-wrapper {
  width: 49rem;
  border-radius: .5rem;
  padding: 1.9rem 2rem 2.1rem 2.6rem;
  .new-service-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .new-service-modal__title {
    font-family: Inter;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    opacity: .85;

  }
  .new-service-modal__sub_title {
    font-family: Inter;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 24px;
    font-weight: 500;
    color: var(--color-black);
    opacity: .75;
    display: flex;
    align-items: center;
    svg {
      margin-left: .6rem;
      width: 1.25rem;
      height: 1.25rem;
      color : #BFBFBF!important;
      &:hover{
        color: black!important;
      }
    }
  }
  .ant-input-number-group-addon {
    background-color: unset;
  }
  .ant-input-number-group-addon span{
    font-weight: 500;
  }
  .new-service-modal__form-group{
    margin-top: 1.6rem;
    .ant-form-item{
      margin-bottom: 0;
    }
    label{
      width: 100%;
    }
    .new-service-modal__delete-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        margin-right: .9rem;
      }
    }
  }
  .new-service-modal__footer{
    margin-top: 1.8rem;
    text-align: right;
  }
  .d-flex{
    display: flex;
    gap: 1.7rem;
  }
}
.ant-radio-wrapper span{
  font-weight: 300;
} 
.ant-checkbox-wrapper span{
  font-weight: 300;
} 
.delete-service-modal{
  width: 40.8rem;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: .2rem;
  padding: 3.2rem 2.4rem;
  text-align: center;
  .delete-service-modal__title{
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 2.4rem;
    margin-bottom: .8rem;
  }
  .delete-service-modal__sub-title{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  .delete-service-modal__icon{
    width: 5.2rem;
    height: 5.2rem;
    background: rgba(228, 78, 97, 0.12);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin : auto;
  }
  .delete-service-modal__footer{
    margin-top: 2.4rem;
    display: flex;
    justify-content: flex-end;
    button{
      margin-left: .8rem;
    }
  }
}

.route-address-modal{
  padding: 2.4rem;
  .modal-footer{
    text-align: right;
    button{
      margin-right: .8rem;
      width: auto;
    }
  }
}

@media screen and (max-width: 992px) {
  .main-title {
    display: none;
  }
  .settings-wrapper {
    background-color: #f6f7fb !important;
    padding-top: .9rem;
    padding-bottom: 4rem;

    .range-input-content {
      width: 100%;
    }
  }
  .settings-sub_title {
    padding-top: 2.7rem;
  }
  .settings-sub_title,
  .settings-sub_description {
    padding-left: 2.4rem!important;
  }
  .notification-content{
    max-width: 22.4rem;
  }
  .preferences-layout {
    background-color: #f6f7fb !important;
    padding: 0 !important;
  }
  .notification-wrapper {
    background-color: #fff;
    &.first-child{
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }
    .address-autocomplete-input {
      margin-top: 1rem !important;
    }
  }
  .notification-description {
    font-size: 1.2rem!important;
  }
  .services-wrapper {
    background: #fff;
    padding: 1.15rem 2.2rem 1.2rem;
    border: 1px solid #d6dbe6;
    border-radius: 8px;

    .notification-description {
      padding-left: 1.3rem;
    }

    .settings-sub_title {
      padding-top: 0 !important;
    }
  }
  .notification-text-wrapper {
    width: 100% !important;
  }
  .supported-services-layout{
    background-color: #fff!important;
    width: 100%;
  }
  .supported-services-btn{
    position: initial!important;
    display: block!important;
    margin-top: 1.2rem!important;
    margin-left: 2.4rem;
  }
  .supported-services-list__item:first-child{
    margin-top: .7rem!important;
  }
  .range-input-modal, .new-service-modal-wrapper, .delete-service-modal{
    width: 100% !important;
  }
}

.new-service-modal__form-group label {
  width: 11rem !important;
  white-space: nowrap;
}