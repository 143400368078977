.container {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding-bottom: 40px;
}

.empty a {
  color: var(--color-blue);
}
.empty a:hover {
  color: var(--color-blue-contrasted);
}
