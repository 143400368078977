.full-screen-top-btn button {
  padding: 15px !important;
}
.gm-style-mtc button {
  padding: 0 17px !important;
}
.add-timeslot-modal-wrapper {
  max-width: 72rem !important;
  top: calc(100vh - (100vh - 43px)) !important;
}
.map-modal .ant-modal-content {
  height: calc(100vh - 86px);
  overflow-y: auto;
}
.map-modal .ant-modal-content::-webkit-scrollbar {
  width: 0.6rem;
}

/* Track */
.map-modal .ant-modal-content::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.map-modal .ant-modal-content::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 0.4rem;
}

/* Handle on hover */
.map-modal .ant-modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ts-footer .ant-form-item {
  margin-bottom: 0px;
}
.add-timeslot-modal-wrapper .ant-form-item-label {
  display: none !important;
}
.add-timeslot-modal-wrapper .ant-modal-header {
  border-bottom: none;
}

.add-timeslot-modal-wrapper .ant-modal-body {
  padding-top: 0rem;
}

.add-timeslot-modal-wrapper .ant-btn-dashed {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.working-areas .ant-form-item {
  margin-bottom: 0rem !important;
}
.address-autocomplete-row {
  display: flex !important;
  margin-top: 0.5rem;
}
.address-autocomplete-wrapper {
  width: 100%;
  align-items: center;
}
.address-autocomplete-delete {
  margin-left: 1.1rem;
  cursor: pointer;
}
.add-working-area-btn {
  background: #edf8fa !important;
  border: 1px dashed #22a3c3 !important;
  border-radius: 0.2rem !important;
}
.add-working-area-btn svg {
  color: #22a3c3 !important;
}
.working-area-title {
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem !important;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  margin-bottom: 0 !important;
}
.working-area-sub-title {
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem !important;
  color: rgba(0, 0, 0, 0.85);
  opacity: 0.5;
  margin: 0 0 0.1rem 0.5rem;
}

.submit-btn-wrapper {
  background-color: #22a3c3 !important;
  border-color: #22a3c3 !important;
  width: 6.7rem !important;
  height: 4rem !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit-btn-wrapper a {
  color: #fff;
}
.submit-btn-wrapper:hover {
  background-color: #7abacc !important;
  border-color: #7abacc !important;
}
.submit-btn-wrapper:hover .submit-btn-wrapper a,
.submit-btn-wrapper a:hover {
  color: #fff !important;
}
.working-areas-textarea {
  resize: none;
  height: 13.1rem !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  margin: 1rem 0 3.9rem !important;
}
.zip-code-select {
  width: 100%;
  margin: 1rem 0 !important;
}
.map-wrapper {
  margin: 1rem 0 3.9rem;
}
.App-map {
  height: 400px;
}
.delete-btn {
  z-index: 99999;
  background: none padding-box rgb(255, 255, 255);
  display: flex;
  align-items: center;
  border: 0px;
  margin: 0px;
  padding: 4px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: left;
  color: rgb(86, 86, 86);
  font-family: Inter, Arial, sans-serif;
  font-size: 11px;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  top: 85% !important;
  bottom: 25px !important;
  height: 24px;
}
.full-screen-btn {
  width: 200px;
  height: 50px;
  font-size: 22px;
  justify-content: center;
}
.delete-btn img {
  width: 15px !important;
}
.full-screen-btn img {
  width: 20px !important;
}
.delete-btn span {
  margin-left: 7px;
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 600;
}
.client-limit-input {
  width: 4rem !important;
  border-right: none !important;
  border-color: #d9d9d9 !important;
}
.client-limit-input:focus {
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
  outline: none !important;
}
.client-limit-input .ant-input-number-group-addon {
  padding: 0;
  background: none;
  border-left: 1px solid transparent;
  padding-right: 12px;
}

.checkbox-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
.address-autocomplete-input {
  margin-top: 0.5rem;
}

@media screen and (max-width: 768px) {
  .add-timeslot-modal-wrapper {
    width: 90% !important;
    height: 100% !important;
  }
}
