@import '~antd/dist/antd.css';
@import '../../../common_repo/styles/variables';



.vet-calendar-steps {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .checkbox-text-wrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  & > form {
    flex: 1;
    display: flex;
    flex-direction: column;
    & > *:first-child {
      flex: 1;
    }
  }
  border-radius: 0.4rem;
  padding: 2.4rem 4rem 2.4rem 4rem;
  background: #fff;
  box-shadow: 0 4px 45px -31px rgba(45, 28, 28, 0.08);

  //.ant-form-item-explain{
  //  display: none!important;
  //}
  .ant-form-item-with-help{
    margin-bottom: 2.4rem!important;
  }
  .ant-steps-item {
    flex: initial !important;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: rgba(255, 154, 5, 1);
    border: none;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: rgba(56, 158, 13, 1);
  }
  span.anticon.anticon-check.ant-steps-finish-icon {
    color: rgba(56, 158, 13, 1);
  }
  .text-right {
    text-align: right;
  }

  .ant-btn-primary {
    background-color: #ff9a05 !important;
    border: 1px solid #ff9a05 !important;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
  }
  .ant-btn-primary[disabled],
  .ant-btn[disabled] {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }


  .ant-steps-item-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-active .ant-steps-item-title {
    color: #ff9a05 !important;
  }
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pet-name-input{
    width: 15.2rem;
  }

  .stepper-footer{
    display: inline-flex;
    align-items: center;
    .error-message{
      color: #FF4D4F;
      font-family: Inter;
      font-weight: 400;
      font-size: 1.4rem;
      margin-right: 1rem;
    }
    .back-btn{
      margin-right: 1.2rem;
      border: 1px solid #D9D9D9!important;
      background-color: #fff!important;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.calendar-page .vet-calendar-steps {
  width: 77.4rem;
}
.new-user-stepper-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.vet-calendar-steps .ant-steps-item{
  flex : 1 0 auto!important;
}
@media only screen and (max-width: $laptopSm) {
  .vet-calendar-steps.booking-page{
    width: 100%;
    padding: 2.4rem 2rem;
  }
}
@media only screen and (max-width: $tablet) {
  .ant-steps-item-tail{
    display: none!important;
  }
  .vet-calendar-steps {
    width: 100%;
    padding: 2.4rem 2rem;
    .ant-picker-calendar-header .ant-picker-calendar-year-select {
      width: 7.4rem;
    }
    .ant-picker-calendar-header .ant-picker-calendar-month-select {
      width: 7.4rem;
      min-width: 7.4rem;
    }
    .ant-picker-calendar-mini .ant-picker-calendar-header {
      float: right;
    }
    .ant-steps-vertical {
      flex-direction: row !important;
    }
    .vet-calendar-steps .ant-steps-item,
    .ant-steps-item-container {
      display: inline-flex;
    }
    .ant-steps-item-wait .ant-steps-item-content {
      display: none !important;
    }
    .vet-calendar-steps
      .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-icon {
      margin-right: 0.8rem;
    }
    .pet-name-input{
      width: 13.9rem;
      margin-left: .8rem;
    }
    .icon-wrapper{
      padding-left: 1.5rem!important;
    }
    .btn-create{
      margin-bottom: 3.6rem!important;
    }
    .patient-details-col{
      width: 100%;
    }
  }
  .ant-steps-item-finish {
    .ant-steps-item-tail,.ant-steps-item-title{
      display: none!important;
    }
  }
}


.note-modal{
  width: 36.5rem;
  max-width: 88vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);
  border-radius: .8rem;
  padding: 2.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
  .d-flex{
    display: flex;
  }
  h3{
    margin-left: 1.6rem;
  }
  p{
    font-family: Inter, assistant;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #36517E;
    margin: 1.6rem 0 1.2rem;
  }
  button {
    max-width: 10.3rem;
    max-height: 5.9rem;
    color: #fff;
  }
}

.vet-calendar-steps.modal {
  padding: 0;
  .personal-details-wrapper {
    padding: 0;
  }
}
