.not-found-wrapper{
  background: #E5E5E5;
  padding: 3.2rem;
  height: 100vh;
  overflow: hidden;
  font-family: Inter, assistant;
  line-height: 3.4rem;
  .not-found-content{
    justify-content: center;
    height: 100%;
    display: flex;
    align-items: center;
    div {
      text-align: center;
    }
    h3{
      font-style: normal;
      font-weight: 600;
      font-size: 3.2rem;
      line-height: 3.4rem;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 3.1rem;
    }
    .not-found-img{
      max-width: 32.9rem;
      margin-bottom: 3.2rem;
    }
    h4{
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      color: rgba(0, 0, 0, 0.85);
    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 2.4rem;
    }
    button{
      max-width: 13.9rem;
      font-size: 1.4rem;
    }
  }
}