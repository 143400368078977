.popoverContainer :global(.ant-popover-inner .ant-popover-inner-content) {
  background-color: unset !important;
  background: unset !important;
  color: var(--neutral-1, #FFF);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.popoverContainer :global(.ant-popover-inner) {
  background: var(--tooltip-75, rgba(0, 0, 0, 0.75)) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.popoverContainer {
  border-radius: 2px;
  color: #FFF;
}
