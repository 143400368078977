.container {
  display: flex;
  align-items: center;
  outline: none;
  gap: 4px;
}

.fileName {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
