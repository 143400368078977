.description {
  color: #8a94a6;
  display: block;
  margin-bottom: 24px;
}

.phone {
  color: #ff9a05;
  white-space: nowrap;
}

.description,
.phone {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
