.gap {
  gap: 8px;
  min-height: 32px;
  align-items: center;
}

.mt24 {
  margin-top: 24px;
}
.btnLeft {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.btnRight {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.noAppsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.view {
  position: relative;
  margin-top: 24px;
  min-height: 400px;
}
.viewMap {
  height: 400px;
}

.modal {
  width: 800px;
}

.modal :global(.ant-modal-header) {
  border-top: none;
  border: none;
}
.modal :global(.ant-modal-content) {
  min-width: 700px;
}

.info {
  color: var(--color-blue) !important;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.title {
  display: flex;
  flex-direction: column;
}

.routeSummary {
  display: flex;
}

.loaderContainer {
  width: 100%;
  height: 100%;
}
