.sign-in-modal{
  width: 53.2rem;
  max-width: 100%;
  .sign-in-modal__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2.4rem;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    h1{
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #323B4B;
    }
  }
  .sign-in-modal__content{
    padding: 2.4rem;
    h3{
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #8A94A6;
      margin-bottom: 2.4rem;
      span{
        color : #FF9A05!important;
      }
    }
  }
  .sign-in-modal__footer{
    padding: 0 2.4rem 2.4rem;
    h4{
      text-decoration: underline;
      font-family: Inter;
      font-style: normal;
      line-height: 22px;
      font-weight: 500;
      font-size: 14px;
      color: #B0B7C3;
      cursor: pointer;
    }
  }
}