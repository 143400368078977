.wrapper {
  display: flex;
  gap: 16px;
  max-width: 500px;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.footer {
  display: flex;
  gap: 8px;
  align-self: flex-end;
  margin-top: 48px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  /* line-height: 32px; */
  color: #000000d9;
}
.subTitle {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 24px; */
  color: #000000d9;
}
.modal {
  margin-top: 20% !important;
  min-width: unset;
}
.icon {
  margin-top: 4px;
}
