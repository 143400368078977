.section {
  font-family: inter;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 20px;
  box-shadow: 0 4px 45px -31px rgba(45, 28, 28, 0.08);
  background: #fff;
  min-height: 400px;
  width: 77.4rem;
}
@media only screen and (max-width: 1080px) {
  .section {
    width: 100%;
  }
}

/* todo: create typography module */
.section h2 {
  width: 100%;
  text-align: center;
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0em;
}

.section h3 {
  margin-top: 24px;
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.text {
  margin-top: 4px;
  font-family: Inter, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #404040;
}

.input {
  margin-top: 8px;
  width: 100%;
  max-width: 300px;
}
.input input::placeholder {
  color: #adacac;
}

.inputError {
  color: #ff4d4f;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.buttonContainer {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.section :global(.ant-btn-primary) {
  width: 100%;
  background-color: #ff9a05 !important;
  border: 1px solid #ff9a05 !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
}
.section :global(.ant-btn-primary[disabled]),
.section :global(.ant-btn[disabled]) {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
