@import '../../../common_repo/styles/mixins';

.edit-clients-modal-wrapper{
  width: 42.9rem;
  height: 50.1rem;
  padding: 2.4rem 4rem;
  position: relative;
  @include sm{
    width: 100%;
  }
  .ant-form-item{
    margin-bottom: .8rem;
  }
  .edit-clients-footer{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 2.4rem;
    right: 4rem;
    button{
      width : 6.7rem;
      height: 4rem;
      border-radius: 2px;
      margin-left: 1.2rem;
    }
  }
}
