.option {
  display: flex;
  padding: 8px;
  border: 1px solid var(--color-white);
  border-radius: 8px;
  gap: 24px;
  background-color: var(--color-white);
  align-items: center;
  cursor: pointer;
}
.option * {
  cursor: default;
}
.option:hover, .active {
  border-color: var(--color-blue)
}

.label {

}
.icon {
  border-radius: 8px;
  background: #22A3C30F;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
