.tag {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  border-radius: 2px;
  border: 1px solid var(--color-gray-D9);
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  background-color: var(--color-gray-FAFAFA);
}
.tag .name {
  min-width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag:hover {
  border-color: inherit;
}
.tag.sm {
  height: 24px;
}
.tag.md {
  height: 32px;
}
.tag.lg {
  height: 40px;
}
.tag .closeIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.tag .closeIcon:hover {
  background-color: var(--color-gray-F0F0F0);
}
.tag .input {
  width: 70px;
  border: unset;
  padding: unset;
  outline: none;
  border-bottom: 1px solid var(--color-gray-D9);
  text-decoration: unset;
  margin: unset;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

.square {
  width: 8px;
  height: 8px;
}
