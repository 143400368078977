.editListCircle {
  display: inline-block; 
  line-height: 0px; 
  border-radius: 50%; 
  background-color: #D3EDF3; 
}

.editListCircle span {
  color: #22A3C3;
  display: inline-block;
  padding-top: 50%; 
  padding-bottom: 50%; 
  margin-left: 8px; 
  margin-right: 8px; 
  font-size: 12px;
  font-weight: 700;
} 

.editExternalIdWarningTitle {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.editExternalIdWarningTitle svg {
  margin-right: 10px;
  width: 22px;
  height: 22px;
}

.editExternalIdWarningContent {
  padding-left: 32px;
  max-width: 500px;
}

.editExternalIdWarningIcon {
  color: #FF9A05 !important;
}

.petModalIntegrationError {
  display: flex;
  align-items: center;
  color: #FF4D4F;
  max-width: 600px;
}

.externalIdTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #BFBFBF;
}

.externalIdTitle > p {
  font-size: 14px;
  padding-bottom: 0.5rem;
  color: #000000D9;
}

.getReferenceIdStep {
  display: flex;
  flex-direction: column;
}

.externalIdNote {
  color: #22A3C3;
}

.externalIdNote span {
  font-weight: 600;
}

.imagePlaceholder {
  width: 100% !important;
}
