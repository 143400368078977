.inputTitle {
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem !important;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  margin-bottom: 0 !important;
}

.hr {
  opacity: 40%;
  width: 100%;
}

.ruleContainer {
  margin-top: 4px;
  border: 1px solid var(--color-gray-F0F0F0);
  padding: 12px;
  width: 100%;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconWithText {
  display: flex;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tooltipText {
  display: inline-block;
  text-wrap: wrap;
  width: 220px;
  color: var(--color-white)  !important;
}

.tooltipTrigger {
  cursor: default;
  display: flex;
  align-items: center;
  padding: 1px 8px;
  gap: 4px;
  border-radius: 2px;
  color: var(--color-orange);
  & * {
    cursor: default;
  }
}
.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}