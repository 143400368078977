.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 24px;
  background-color: var(--color-white);
  width: 100%;
  min-height: 85vh;
}

.sticky {
  background-color: var(--color-white);
  position: sticky;
  top: 0px;
  z-index: 1;
  padding-top: 5px;
  margin-top: -5px;
  padding-bottom: 16px;
}

.areas {
  display: flex;
  flex-direction: column;
}

.controls {
  gap: 1rem;
  padding-bottom: 8px;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;
}
.controls .toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.controls .toggle button:first-child {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.controls .toggle button:last-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.buttonAreasView {
  z-index: 1;
  position: absolute;
  bottom: 50px;
  padding: 12px 16px !important;
  height: unset !important;
  right: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Inter, serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (max-width: 650px) {
  .buttonAreasView {
    padding: 6px 8px !important;
    line-height: 14px;
  }
}
