.filters-wrapper {
  display: flex;
  gap: 2.4rem;
  margin-bottom: 2rem;
  background-color: #ffffff;
}
.mb-30 {
  margin-bottom: 30px;
}
.filter-top {
  display: flex;
  gap: 1.6rem;
}

.customers-filter-datepicker {
  width: 100% !important;
}

.filter-bottom {
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
}

.filter-item {
  width: 19.8rem;
}

.filter-item .ant-input {
  height: 2.2rem !important;
}

.filter-item .ant-input-affix-wrapper {
  align-items: center;
}

.filter-item .ant-input-prefix {
  height: 1.4rem;
  margin-right: 1.2rem;
}

.filter-item-search {
  width: 32.8rem;
}

.status-wrapper {
  border: 0.1rem solid #ccc;
  border-radius: 0.4rem;
  padding: 0.2rem 1rem;
  display: flex;
  gap: 1.6rem;
}

.ant-popover-inner {
  background: rgba(0, 0, 0, 0.1) !important;
}

.ant-popover-inner-content {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
}

.ant-popover-arrow-content {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.popover {
  display: flex;
  flex-direction: column;
}

.location-icon {
  display: flex;
  flex-wrap: nowrap;
}

.single-popover {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0.8rem;
}

.popover-address {
  font-weight: bold;
  width: 6rem;
  margin-right: 0.5rem;
}

.popover-icon {
  color: #bfbfbf !important;
  margin-right: 1.3rem;
  display: flex !important;
  align-items: center;
}

.popover-icon > svg {
  min-width: 1.79rem;
  min-height: 1.79rem;
}

.popover-location-text {
  margin-left: 3.2rem;
}

.listWrapper {
  display: flex;
  align-items: flex-end;
  margin: 0 10px 15px 0;
}

.totalsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 50px;
  padding: 0;
  width: calc(100% - 90px);
  margin: 0 0 10px 0;
}

.totalsListItem {
  width: 250px !important;
  margin-bottom: 30px !important;
  text-align: center;
}

.exportList {
  cursor: pointer;
  width: 90px;
  height: 32px;
}

.action-buttons-wrapper {
  display: flex;
  gap: 0.7rem;
  align-items: center;
}

.action-button {
  cursor: pointer;
  font-size: 1.6rem;
  color: #bfbfbf !important;
}

.action-button:hover {
  color: #22a3c3 !important;
}

.ant-picker {
  width: 19.8rem;
}

.contacts-column:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}

.ant-table-tbody {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-table-tbody:hover {
  background: rgba(250, 250, 250, 0.64);
  cursor: pointer;
  color: #000000 !important;
}
.customers-search-input button{
  height: 3.2rem!important;
}

.fixed-size-input .ant-form-item-control{
  height: 3.2rem;
}