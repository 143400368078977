.monthWrapper :global(button) {
  display: flex;
  align-items: center;
}
.monthWrapper :global(span) {
  text-transform: capitalize;
}

.container :global(.react-datepicker__aria-live) {
  display: none;
}
