.timeslot-row {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.3em;
  text-transform: capitalize;
}
.timeslot-row:hover {
  text-overflow: ellipsis;
  overflow: visible;
  white-space: normal;
}
.additional-info-wrapper .property-type-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.additional-info-wrapper .additional-info {
  font-family: Inter, serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
}

.add-address-button {
  color: var(--color-blue) !important;
  font-size: 12px;
  cursor: pointer;
  font-style: normal;
  text-decoration: underline;
}

.address-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
