.timeslot-button {
  color: rgba(0, 0, 0, 0.85) !important;
  /* color: red !important; */
}

.hours-wrapper {
  white-space: nowrap;
}

.working-area-wrapper {
  display: flex;
  gap: 0.1rem;
  justify-content: center;
  align-items: center;
}

.more-btn {
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
  color: #fff;
  padding: 0.2rem;
}

.routes-left-column {
  height: auto !important;
  margin-bottom: 2rem !important;
}

.warnings-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loop-once-icon {
  margin-right: 12px;
  left: 0px;
}
