.nameCell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.warningsCell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.warningIcon {
  margin-right: 4px;
  left: 0px;
}

.warningIcon > svg {
  color: #ff4d4f;
}

.warn {
  color: var(--color-orange);
  position: absolute;
  margin-right: -14px;
  margin-top: -14px;
}

.loopOnceIcon {
  margin-right: 12px;
  left: 0px;
}
