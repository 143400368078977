.limit-reached-wrapper {
  width: 100%;
  height: 334px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55px 0 77px;
  .limit-reached-box {
    text-align: center;
    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin: 24px 0 12px;
    }
    h4 {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
      span {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    a {
      width: 184px;
      height: 56px;
      background: #ff9a05;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: inter, assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin: 24px auto 0;
    }
  }
}
