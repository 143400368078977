.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 64rem;
}
.option {
  margin-top: 16px;
}
.selected {
  color: #40a9ff;
  border-color: #40a9ff;
}
.confirm {
  margin-top: 48px !important;
  height: 58px !important;
  border-radius: 8px !important;
  font-family: Inter, serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 29px !important;
  width: 100% !important;
}

.confirm[disabled] {
  background: var(--color-blue-disabled) !important;
  color: var(--color-white) !important;
}

.label {
  margin-top: 13px;
  margin-bottom: 22px;
}

@media screen and (max-width: 480px) {
  .container {
    padding: 50px 10px;
    padding-bottom: 0px;
  }

  .container > span {
    padding: 0 10px;
  }

  .container .label {
    margin-bottom: 0px;
    margin-top: 25px;
  }
}
