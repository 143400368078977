.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
    height: unset;
  }
}

.modal {
  max-width: 436px;
  min-width: unset;
}