.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close {
}

.title {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.buttonConfirm {
  width: 100% !important;
}

.time {
  display: flex;
  border: 1px solid var(--color-gray-ced4d9);
  padding: 8px;
  align-items: center;
  cursor: pointer;
}

.selectedTime {
  display: flex;
  border: 1px solid #22a3c3;
  padding: 8px;
  align-items: center;
  box-shadow: 0px 0px 3px 3px rgba(34, 163, 195, 0.12);
}
