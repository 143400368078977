@import '../../common_repo/styles/mixins';
.appointment-duration-modal {
  width: 41.6rem;
  height: 25.1rem;
  padding: 3.2rem;
  @include sm{
    width: 36rem;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;

    svg {
      margin-right: 1.6rem;
      margin-left: 0;
    }
  }

  p {
    font-family: inter, assistant;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #36517E;
    margin-left: calc(2.4rem + 1.6rem);
    strong {
      font-family: Inter;
      font-weight: 600;
    }
  }

  .duration-input-wrapper {
    margin-left: calc(2.4rem + 1.6rem);
    width: 17.2rem;
  }

  .suffix {
    font-family: inter, assistant;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #112950;
    opacity: 0.4
  }

  .footer-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.4rem;

    .cancel-btn {
      background: white;
      border: 1px solid #FF9A05;
    }

    button {
      margin-left: 1.2rem;
      height: 3.2rem;
      width: 6.5rem;
      border-radius: 2px;
    }
  }
}
