.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 999;
  height: 100%;
  display: none;
}

.display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  border-radius: 8px;
  padding: 32px;
  transition: 0.05s;
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: #FFFFFF;
  box-shadow: 0px 16px 45px 0px rgba(0, 0, 0, 0.0784313725);
}
.body .withIcon {
  padding-left: 43px;
}

@media screen and (max-width: 399px) {
  .body {
    min-width: 100%;
  }
}
@media screen and (min-width: 400px) {
  .body {
    min-width: 400px;
    max-width: 100%;
  }
}
@media screen and (min-width: 600px) {
  .body {
    min-width: 400px;
  }
}
@media screen and (min-width: 800px) {
  .body {
    min-width: 500px;
  }
}
@media screen and (min-width: 1000px) {
  .body {
    min-width: 600px;
  }
}
@media screen and (min-width: 1200px) {
  .body {
    min-width: 700px;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.headerTitleContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.headerIcon {
  height: 28px;
  font-size: 28px;
  line-height: 28px;
  color: var(--color-orange);
}

.headerTile {
  color: var(--character-title-85);
}

.header .headerCloseButton[type=button] {
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
}
.header .headerCloseButton[type=button] svg {
  margin: 0;
}

.footer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}

.footerReverse {
  flex-direction: row-reverse;
}

.footerCenter {
  justify-content: center;
}

.footerLeft {
  justify-content: flex-start;
}

.footerRight {
  justify-content: flex-end;
}

.footer button {
  min-width: 100px;
}
