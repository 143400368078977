.container {
  display: flex;
  align-items: center !important;
}

.container :global(.ant-checkbox-inner) {
  border-color: var(--color-blue) !important;
  width: 20px;
  height: 20px;
}
.container :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--color-blue);
}

.container :global(.ant-checkbox-inner::after) {
  width: 6px;
  height: 10px;
  left: 25%;
}
.container :global(.ant-checkbox-indeterminate .ant-checkbox-inner::after) {
  background-color: var(--color-blue) !important;
  left: 50% !important;
  width: 10px;
  height: 10px;
}

.container :global(.ant-checkbox) {
  top: 0 !important;
}
