.container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.address {
  font-family: Inter, serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
}
