#add-pet-text {
  color: #000000d9;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
  user-select: none;
}

#advanced-info-text {
  color: #000000d9;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
  cursor: pointer;
  user-select: none;
}

.pet-modal-form .ant-row-end .ant-form-item {
  margin-bottom: 0;
}

.advanced-info-wrapper--hidden {
  max-height: 0rem;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.advanced-info-wrapper {
  max-height: 100rem;
  transition: all 0.3s ease-in-out;
}

#fill-info-text {
  color: #000000d9;
  opacity: 0.64;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  font-weight: 400;
}

#arrow-icon {
  margin-left: 0.5rem;
  transition: all 0.2s ease-in-out;
  width: 1.1rem;
}

#arrow-icon-rotated {
  margin-left: 0.5rem;
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg);
  width: 1.1rem;
}

.pet-modal-form .ant-upload-select-picture-card {
  width: 100% !important;
  height: 130px !important;
}

.pet-modal-form .ant-upload {
  width: 100% !important;
  height: 100% !important;
}

.cancel-button {
  height: 4rem !important;
}

.pet-modal-form .avatar-image {
  max-height: 130px !important;
}
