.item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 246px;
  height: 220px;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);
}

.itemDisabled {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.itemDisabled > span {
  margin-top: 12px;
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

/* icons */
.iconOutlined * {
  color: rgb(34, 163, 195);
  margin-left: 12px;
}

.item:hover {
  border: 1px solid #22A3C3;
}

.item:hover .itemDisabled > div {
  background: #22A3C3;
}
