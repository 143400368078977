.container {
  display: flex;
  width: 100%;
  height: 100%;

  background: url('../../assets/images/shop-background.png');
  background-size: contain;
  align-items: center;
  justify-content: center;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.4rem;
  color: rgba(0, 0, 0, 0.85);
}

.desc1 {
  color: #262626;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.desc2 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.desc3 {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.desc4 {
  color: var(--neutral-9, #434343);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.section {
  border-radius: 12px;
  background: #f5f6f9;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.modal {
  display: flex;
  width: 310px;
  padding: 32px 20px 20px 20px;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 45px -31px rgba(45, 28, 28, 0.08);
}
.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.btn {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
