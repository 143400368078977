.customTooltip {
  box-shadow: 0px 16px 45px 0px #00000014;
  background-color: #fff;
  width: 276px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  gap: 20px;
  margin-bottom: 16px;
}

.label {
  white-space: nowrap;
}

.legend {
  width: 10px;
  height: 10px;
  border-radius: 1px;
  margin-right: 8px;
}
.flex {
  display: flex;
  align-items: center;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
