.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.address :global(.ant-form-item) {
  margin-bottom: 4px !important;
}
.mailIcon {
  color: var(--color-blue) !important;
}

.input {
  min-width: 200px;
}
.textarea {
  min-height: 100px !important;
}

.modal :global(.ant-modal-header) {
  border-bottom: unset !important;
}
