.container {
  display: flex;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.goBackButton {
  background: #ff9a05;
}

.missingInfoModal {
  margin-top: 200px !important;
  max-width: 485px;
  min-width: unset;
}
