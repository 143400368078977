.dropdown :global(.ant-dropdown-menu) {
  border-radius: 8px;
  overflow: hidden;
  padding: 16px 0 !important;

}

.dropdown {
  padding: 0px;
  border-radius: 8px;
  border: 1px solid var(--color-DBE2EE);
}
