.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.sm {
  height: 16px;
  width: 16px;
  min-width: 16px;
}
.md {
  min-width: 24px;
  height: 24px;
  width: 24px;
}
.lg {
  height: 32px;
  width: 32px;
  min-width: 32px;
}
.xl {
  height: 64px;
  width: 64px;
  min-width: 64px;
}
.container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
