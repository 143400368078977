.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 16px;
}

.flex {
  display: flex;
  flex: 1;
  flex-wrap: unset;
}

@media (max-width: 800px) {
  .flex {
    flex-wrap: wrap;
  }
}
