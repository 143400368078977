.form {
    display: flex;
    flex-direction: column;
}

.label {
    margin-top: 16px;
}

.petswebButtons {
    display: flex;
    gap: 8px;
}
.petswebButtons button {
    margin-top: 36px;
    width: 100%;
}

.vetsButtons {
    margin-top: 36px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}
:global(textarea.ant-input).textarea {
    height: 150px !important;
}
