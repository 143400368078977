@import '../../../common_repo/styles/variables';
@import '../../../common_repo/styles/antd-custom';

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .sign-in-url {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    color: #22a3c3;
  }
}
.mobile-copyright {
  position: sticky;
  bottom: 0;
  background: #fff;
}
.copyright.justify-flex-end {
  justify-content: flex-end;
  padding: 0 3.2rem;
}
.copyright.justify-space-between {
  justify-content: space-between;
  padding: 0 3.2rem;
}
.copyright-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  h3 {
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    opacity: 0.8;
    margin-top: 1rem;
  }
  img {
    width: 5.6rem;
    margin-left: 0.8rem;
  }
}
.copyright-wrapper.copyright-full-width {
  height: auto;
  background-color: #fff;
  justify-content: center;
  margin-top: 2.4rem;
  padding: 0rem 0 2rem;
}

@media only screen and (max-width: $mobile) {
  .copyright {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
  }
}
