.container {
  display: flex;
  justify-content: center;
  height: 80vh;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
    height: unset;
  }
}

.modal {
  max-width: 436px;
  min-width: unset;
}