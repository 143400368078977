.ant-form-item-label {
  padding: 0rem !important;
}

.ant-input {
  height: 3.2rem !important;
}

svg {
  cursor: pointer;
}
