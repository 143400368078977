.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.loaderWrapper {
  bottom: -12px;
  left: -24px;
  position: absolute;
  width: calc(100% + 48px);
  height: calc(100% + 24px);
}
.btnLeft {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.btnRight {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.caregivers {
  display: flex;
  flex-direction: column;
}

.btnAdd {

}

.tableRadio {

}

.header {
  display: flex;
  justify-content: space-between;

}
.header > div {
  align-items: center;
}
.header > div > span {
  margin-right: 10px;
}
.view {
  height: 100%;
  max-height: 240px;
  margin-top: 14px;
  overflow-y: auto;
}
.footer {
  margin-top: 12px;
  display: flex;
}
.footer > * {
  flex: 1;
}

.table {
  height: 100%;
}
.view > div {
  height: 100%;
}
.view :global(.ant-table-thead > tr > th),
.view :global(.ant-table-tbody > tr > td),
.view :global(.ant-table tfoot > tr > th),
.view :global(.ant-table tfoot > tr > td) {
  padding: 10px 10px;
}

.view :global(.ant-spin-nested-loading) {
  height: 100%;
  min-height: unset;
}

