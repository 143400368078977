.back {
  display: flex;
  gap: 10px;
  align-items: baseline;
  text-decoration: none;
}
.back:hover, .back:active, .back:visited {
  color: var(--character-title-85);
}
.back svg {
  fill: var(--character-title-85);
}

.petOwnerDescription {
  display: flex;
  justify-content: center;
}

.petOwnerDescription b {
  color: var(--color-black-o40);
}
